export const IS_DEVELOPMENT_ENV = process.env.NEXT_PUBLIC_IS_DEVELOPMENT_ENV == 'true'
export const UI_VIEW = process.env.NEXT_PUBLIC_UI_VIEW === 'true'
export const SHOW_CONSOLE = process.env.NEXT_PUBLIC_CONSOLE === 'true'

export const ENV_INFO = {
  root: process.env.NEXT_PUBLIC_ROOT,
  apiRoot: process.env.NEXT_PUBLIC_API_ROOT,
  HTExchangeUrl: process.env.NEXT_PUBLIC_HT_EXCHANGE_URL,
  gtm: process.env.NEXT_PUBLIC_GTM_ID,
  hotaiId: process.env.NEXT_PUBLIC_HOTAI_ID,
  hotaiGoUrl: process.env.NEXT_PUBLIC_HOTAIGO_URL,
  CreditCardNormal: process.env.NEXT_PUBLIC_CREDIT_CARD_BINDING_NORMAL,
  CreditCardQuickly: process.env.NEXT_PUBLIC_CREDIT_CARD_BINDING_QUICKLY,
  version: process.env.version,
  apiPaymentCheckoutInCartInfo: process.env.NEXT_PUBLIC_API_PAYMENT_CHECKOUT_IN_CART_INFO === 'true',
}

export const APP_ID = 'ECWEB'
export const CRYPTO_TYPE = 'S256'
export const OAUTH_GRANT_TYPE = 'authorization_code'

export const LOCAL_STORAGE = {
  // PERSIST_ROOT: 'persist:root',
  TOKEN: 'jwt',
  POINT: 'point',
  CODE_VERIFIER: 'codeVerifier',
  PROFILE_REFRESH: 'profileRefresh',
  PREVIOUS_PAGE_PATH: 'previousPagePath',
}

export const SESSION_STORAGE = {
  STASH_CART: 'stashCart',
  STASH_PATH_NAME: 'stashPathName',
}

export const AUTH_PAGE = ['/account', '/cart']