import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'
import Link from 'next/link'
import Image from 'next/image'
import $isEmpty from 'lodash/isEmpty'
import { handleCheckBannerTime } from 'utils/date'
import SwiperButton from 'page-components/home/SwiperButton'
import Slider from 'common/Slider'

const KV_BREAKPOINTS = {
  320: {
    slidesPerView: 1.2,
    spaceBetween: 10,
  },
  576: {
    slidesPerView: 1.2,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 1.2,
    spaceBetween: 20,
  },
  1366: {
    slidesPerView: 1.2,
    spaceBetween: 30,
  },
  1440: {
    slidesPerView: 1.35,
    spaceBetween: 40,
  },
  1600: {
    slidesPerView: 1.615,
    spaceBetween: 40,
  },
}

const KeyVision = (props) => {
  const {
    className,
    id,
    kvList,
    backgroundImage,
    random,
  } = props
  
  const [newKvList, setnewKvList] = useState([])

  const processArray = (originKvList) => {

    let imgData = [...originKvList]

    let tempKvList = []
    // 是否開啟 亂數顯示
    if (random) {
      const cloneList = imgData.reduce((
        acc, item, index,
      ) => {
        acc[index] = {
          ...item,
          id: item.id * 10 + Math.floor(Math.random() * 100 ) + index, 
        }
        return acc
      }, [])
      tempKvList.push(...cloneList)
    } else {
      // 未開啟亂數，預設依據 id 進行升冪排序
      tempKvList = imgData.sort((a, b) => a.id - b.id).map((item, index) => ({
        ...item,
        id: index + 1,
      }))
    }

    // 受限於 Slider 套件限制，當張數 < 6 張在顯示上會有問題，官方文件建議補足滿6張以上
    let kv = tempKvList
    while (kv.length < 20) {
      kv.push(...tempKvList)
    }

    setnewKvList(kv)
  }

  useEffect(() => {
    if (kvList.length > 0) {
      let list = []
      kvList.map((item) => {
        if (handleCheckBannerTime(item?.date?.start, item?.date?.end)) list.push(item)
      })

      processArray(list)
    }
  }, [kvList])

  return (
    <>
      {/* 滿版KV + TITLE & description */}
      <section
        id={id}
        className={classReader({ KeyVisionStyled: ['bg'] }, className)}
        // style={{ backgroundImage: `url(${backgroundImage.src})` }}
      >
        <div className={classReader('container d-flex align-items-center justify-content-center', { KeyVisionStyled: ['container'] })}>
          <Link
            href={backgroundImage.link}
            prefetch={false}
            passHref
            replace={!Boolean(backgroundImage?.link === '')} // 當 link = '' 設為 false，只更新紀錄，不觸發刷新
            scroll={!Boolean(backgroundImage?.link === '')} // 當 link = '' 設為 false，Next.js 將不會嘗試捲動到第一個 Page 元素
          >
            <div className={classReader('text-center', { KeyVisionStyled: ['info'] })}>
              {Boolean(backgroundImage.title) && (
                <h1 className={classReader({ KeyVisionStyled: ['title'] })}>{backgroundImage.title}</h1>
              )}
              
              {Boolean(backgroundImage.desc) && (
                <p className={classReader({ KeyVisionStyled: ['desc'] })}>{backgroundImage.desc}</p>
              )}
            </div>
          </Link>
        </div>
      </section>
      {/* 
      {backgroundImage.src > 0 && (
        <section
          id={id}
          className={classReader({ KeyVisionStyled: ['bg'] }, className)}
          style={{ backgroundImage: `url(${backgroundImage.src})` }}
        >
          <div className={classReader('container d-flex align-items-center justify-content-center', { KeyVisionStyled: ['container'] })}>
            <Link
              href={backgroundImage.link}
              prefetch={false}
              passHref
            >
              <div className={classReader('text-center', { KeyVisionStyled: ['info'] })}>
                {Boolean(backgroundImage.title) && (
                  <h1 className={classReader({ KeyVisionStyled: ['title'] })}>{backgroundImage.title}</h1>
                )}
                
                {Boolean(backgroundImage.desc) && (
                  <p className={classReader({ KeyVisionStyled: ['desc'] })}>{backgroundImage.desc}</p>
                )}
              </div>
            </Link>
          </div>
        </section>
      )} */}

      {/* 輪播KV */}
      {$isEmpty(kvList) === false && (
        <section id={id} className={classReader({ KeyVisionStyled: 'option' }, className)}>
          <Slider
            className={classReader({ KeyVisionStyled: 'slider' })}
            styleName="KeyVisionStyled"
            initialSlide={newKvList.length}
            slidesPerView={1.2}
            breakpoints={KV_BREAKPOINTS}
            slides={newKvList.map((kvItem, index) => ({
              id: `slider${index}`,
              content: (
                <Link
                  href={kvItem.link}
                  target={kvItem.target}
                  className={classReader({ KeyVisionStyled: 'link' }, { pure: 'gtm-key-vision' })}
                  aria-label={kvItem.label}
                  passHref
                >
                  <div className={classReader({ KeyVisionStyled: 'slider__img' })}>
                    <picture>
                      {Boolean(kvItem.src.pc) && (
                        <source srcSet={kvItem.src.pc} media="(min-width: 1200px)" />
                      )}

                      {Boolean(kvItem.src.pad) && (
                        <source srcSet={kvItem.src.pad} media="(min-width: 576px)" />
                      )}

                      <Image
                        src={kvItem.src.m}
                        alt={kvItem.label}
                        width={447}
                        height={251}
                        priority
                      />
                    </picture>
                  </div>
                </Link>
              ),
            }))}
            // effect="coverflow"
            // coverflowEffect={{
            //   rotate: 0,
            //   stretch:0,
            //   depth: 100,
            //   modifier: 1,
            //   slideShadows: false
            // }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            customLeftNavigation={(prevFunc) => (
              <SwiperButton icon="stroke-arrow-left" onClick={prevFunc} />
            )}
            customRightNavigation={(nextFunc) => (
              <SwiperButton icon="stroke-arrow-right" onClick={nextFunc} />
            )}
            centeredSlides
            loop
          />
        </section>
      )}
    </>
  )
}

KeyVision.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  kvList: PropTypes.array,
  backgroundImage: PropTypes.object,
  random: PropTypes.bool,
}

KeyVision.defaultProps = {
  kvList: [],
  backgroundImage: { link: '' },
  random: false,
}

export default KeyVision