import { LOGIN_DURATION } from 'config/token'
import { SHOW_CONSOLE } from 'config/config'

let timerId = null
let numberExecutions = 0 // 執行次數

const TimerManager = {
  /**
   * 啟動計時器，避免重複設置
   * @param {Function} callback - 下一次執行時調用的函數
   * @param {number} delayMinute - 執行間隔，單位分鐘
   */
  start: (callback, delayMinute = LOGIN_DURATION) => {
    TimerManager.stop() // 確保清除舊的計時器

    numberExecutions = 0 // 初始化秒數
    if (SHOW_CONSOLE) console.log(`計時器啟動，${delayMinute}分鐘後更換 token`)
        
    timerId = setTimeout(() => {
      // 累計執行次數
      numberExecutions++
      callback(numberExecutions) 
    }, delayMinute * 60 * 1000) // N 分鐘執行
  },

  /**
   * 停止計時器
   */
  stop: () => {
    if (SHOW_CONSOLE) console.log('開始清理計時器')
    if (timerId) {
      if (SHOW_CONSOLE) console.log('計時器已停止')
      clearInterval(timerId)
      timerId = null
    } else {
      if (SHOW_CONSOLE) console.log('沒有計時器可停止')
    }
  },
}

export default TimerManager
