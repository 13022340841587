import { ENV_INFO, SHOW_CONSOLE } from 'config/config'
import { DATETIME_FORMAT, DATE_FORMAT } from 'config/date'
import { UNIVERSAL_STUDIOS } from 'config/page/product'
import { TAB_TITLE_SUFFIX } from 'config/head'
import { CITY_DATA, COUNTRY_DATA } from 'config/options'
import { sanitizeForHead } from 'utils/util'

import classReader from './classReader'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isBetween)

// 針對接機商品，新增一行敘述(NEW_ELEMENT_FOR_PICK_UP_PRODUCT) [EasonLiu]
const DESC_FOR_TITLE = '請注意：'
const DESC_FOR_PICK_UP_PRODUCT = '**最終接駁時間會於出發前一日晚間7點提供'
const DESC_FOR_EXTRA_FEE = '**商品需額外收費部分'
const PICK_UP_PRODUCT_KEYWORDS = [
  '接送',
  '接駁',
  '接機',
  '一日遊',
  '半日遊',
  '包團',
]
const EXTRA_FEE_PRODUCT_KEYWORDS = ['額外費用', '加價費用']
const checkIsPickUpProduct = (productName) => PICK_UP_PRODUCT_KEYWORDS.some(keyword => productName?.includes(keyword))
const checkIsExtraFeeProduct = (productDesc) => EXTRA_FEE_PRODUCT_KEYWORDS.some(keyword => productDesc?.includes(keyword))

const kkDayPackageConvert = (
  productPackage = {}, isPickUpProduct = false, isExtraFeeProduct = false,
) => {
  const newPackage = { ...productPackage }

  const packageIntroPickUpProductConvert = (content) => {
    if (content === '') {
      return `
      <div easonclass='text-primary mt-4 mb-2'>
        <p easonclass='m-0'>${DESC_FOR_TITLE}</p>
        ${isPickUpProduct ? `<p easonclass='my-1'>${DESC_FOR_PICK_UP_PRODUCT}</p>` : ''}
        ${isExtraFeeProduct ? `<p easonclass='my-1'>${DESC_FOR_EXTRA_FEE}</p>` : ''}
      </div>`
    }
    if (!content.includes('ul') && content.includes('p')) {
      return `
      <div easonclass='text-primary'>
        <p>${DESC_FOR_TITLE}</p>
        ${isPickUpProduct ? `<p>${DESC_FOR_PICK_UP_PRODUCT}</p>` : ''}
        ${isExtraFeeProduct ? `<p>${DESC_FOR_EXTRA_FEE}</p>` : ''}
      </div>`
    }
    return content.replace('</ul>', `<li easonclass="text-primary"><p easonclass="m-0">${DESC_FOR_TITLE}</p><p easonclass="m-0">${isPickUpProduct ? DESC_FOR_PICK_UP_PRODUCT : ''}</p><p easonclass="m-0">${isExtraFeeProduct ? DESC_FOR_EXTRA_FEE : ''}</p></li></ul>`)
  }

  // 判斷是否為接機商品，新增一行敘述(NEW_ELEMENT_FOR_PICK_UP_PRODUCT) [EasonLiu]
  const packageIntro = newPackage.productItemSpecDesc?.speC_INTRODUCTION?.content
  const packageIntroReformat = (isPickUpProduct || isExtraFeeProduct) ? packageIntroPickUpProductConvert(packageIntro) : packageIntro

  // 已修正，原本為 list ，但雄獅這邊改為 html [EasonLiu]
  newPackage['packageIntro'] = packageIntroReformat || ''

  newPackage['specLimit'] = {
    moduleTitle: Boolean(newPackage.productItemSpecDesc.speC_LIMIT) ? '方案限制' : '',
    content: newPackage.productItemSpecDesc?.speC_LIMIT?.content ?? '',
  }

  newPackage['packageAbout'] = {
    moduleTitle: Boolean(newPackage.deliveryTimeUnit || newPackage.orderConfirmTimeUnit) ? '關於此方案' : '',
    content: {
      deliveryTime: newPackage.deliveryTime || 0,
      deliveryTimeUnit: newPackage.deliveryTimeUnit || '',
      orderConfirmTime: newPackage.orderConfirmTime || 0,
      orderConfirmTimeUnit: newPackage.orderConfirmTimeUnit || '',
      orderProcessTimeEnd: newPackage.orderProcessTimeEnd || '',
      orderProcessTimeStart: newPackage.orderProcessTimeStart || '',
    },
  }

  newPackage['refundPolicy'] = {
    moduleTitle: Boolean(newPackage.refundPolicy) ? '取消政策' : '',
    content: {
      policyType: newPackage.refundPolicy || '',
      refunds: newPackage?.refunds,
      speCRefundPolicy: newPackage?.productItemSpecDesc?.speC_REFUND_POLICY || '', 
    },
  }

  // 2024/02/21 新增票券型商品使用期限的敘述 [EasonLiu]
  newPackage['pakageValid'] = {
    moduleTitle: newPackage.productItemSpecDesc?.speC_USE_VALID?.title ?? '',
    content: newPackage.productItemSpecDesc?.speC_USE_VALID?.content ?? '',
  }

  // 備用，暫時無任何內容 [EasonLiu]
  newPackage['specMoreInfo'] = {
    moduleTitle: Boolean(newPackage.productItemSpecDesc.speC_MORE_INFO.title) ? '更多資訊' : '',
    content: newPackage.productItemSpecDesc?.speC_MORE_INFO?.content ?? '',
  }

  /* 後續前端在資料處理上，採用取得 packageAllDates 最後一值，當作最終販售日期
   * 而原先邏輯只取得 dates，並不完善，忽略了後端傳來中可能出現的
   * 1. 日期資料有可能未依照升冪排序
   * 2. 日期可能包含已過期日期
   * 使其出現邏輯誤區，當日期未排序，前端商品顯示將錯誤
   * 
   * 此處加上
   * 1. 日期過濾: 過濾小於今天日期(過期日期)
   * 2. 升冪排序: 針對可販售日期進行排序
   * 使其 dates / packageAllDates 資料符合後續資料處裡方式
  */


  /* 為了避免原先使用的寫法
   * newPackage['packageAllDates'] = [...new Set(productPackage.productItemOptions?.flatMap(item => item.dates.filter(e => e >= dayjs().format(DATE_FORMAT)).sort()))] 
   * 在資料整理上， 出現日期缺漏
   * 
   * 此處改為一一檢查/新增
  */
  let dataAllArray = []
  productPackage.productItemOptions?.forEach(item => {
    if (item?.dates && Array.isArray(item.dates)) {
      item.dates.forEach( itemDate => {
        // 排除重複、過期日期s
        if (!dataAllArray.includes(itemDate) && itemDate >= dayjs().format(DATE_FORMAT)) dataAllArray.push(itemDate)
      })
    }
  })

  // 排續
  newPackage['packageAllDates'] = dataAllArray.sort()

  // 取得最後一個規格的第一項可獨立購買票種做為月曆顯示最低價格依據 [EasonLiu]
  const ticketTypes = Array.isArray(newPackage?.productItemSpecs) && newPackage?.productItemSpecs.length > 0 ? newPackage?.productItemSpecs.at(-1).specItems : ''

  function getDefaultId(index = 0) {
    const isIndependOptionsSpecValues = newPackage?.productItemOptions?.filter(option => option.isIndepend).map(filterOpt => filterOpt.specRefs[filterOpt.specRefs.length - 1].specValue)
    if ((!isIndependOptionsSpecValues?.includes(ticketTypes[index]?.id) || newPackage?.productItemOptions.some(option => option.specRefs.some(spec => spec.specValue === ticketTypes[index].id))) && ticketTypes.length > index + 1) return getDefaultId(index + 1) 
    return ticketTypes[index]?.id
  }

  const productItemSpecValue = getDefaultId()

  // 判斷價格是否有變化 [EasonLiu]
  let isPriceDiff = false

  // 先去除不可獨立購買之票種，再取得最低價格 [EasonLiu]
  let onlyIndependOptions = []
  let onlydependOptions = []
  if (newPackage && Array.isArray(newPackage?.productItemOptions)) {
    onlyIndependOptions = newPackage.productItemOptions?.filter(option => option.isIndepend)
    onlydependOptions = newPackage.productItemOptions?.filter(option => !option.isIndepend)
  }
  // console.log('onlyIndependOptions', onlyIndependOptions)
  // console.log('onlydependOptions', onlydependOptions)
  // 取得可獨立購買之票種組合每一天最低價格(為了月曆可以快速尋找最低價) [EasonLiu]
  function getOptionsDatePrices(options){
    return options?.reduce((acc, option) => {
      const datesPriceObject = option.optionDates
      const priceDateObj = {}
      let optionPrice = 0
      let isOptionPriceDiff = false
  
      Object.entries(datesPriceObject).forEach(([date, { priceSession }], index) => {

        const price = Object.keys(priceSession).length === 0 ? 0 : priceSession.hasOwnProperty('fullday') ? priceSession.fullday : Math.min(...Object.values(priceSession))

        /*
         * 價格邏輯
         * 如果 price = 0，表示 priceSession = {}
         * 當 priceSession = {}，代表商品資料來源中的價格，可能出現問題
         * 前端顯示 - 資料處理上，視同當日該項商品價格無效，不可販售
         * 
         * 所以此處進行無效商品防呆/過濾
         */
        if (price > 0) {
          if (!priceDateObj['price' + price]) priceDateObj['price' + price] = []
          if (optionPrice === 0) optionPrice = price
          if (optionPrice !== price) isOptionPriceDiff = true
          priceDateObj['price' + price] = [...priceDateObj['price' + price], date]
          if ((!acc[date] || acc[date].price > price) && option.specRefs.some(spec => {
            return spec.specValue === productItemSpecValue})) {
            if (acc[date] && acc[date].price > price) isPriceDiff = true
            acc[date] = { price }
          }
        }

      })
      option.isOptionPriceDiff = isOptionPriceDiff
      option.priceDate = priceDateObj
      return acc
    }, {})
  }

  // 這裡的區分獨立、非獨立 Options 價格(避免找最低價時，出現誤導顧客的金額)
  const [onlyIndependOptionsPrices, onlydependOptionsPrices] = [getOptionsDatePrices(onlyIndependOptions), getOptionsDatePrices(onlydependOptions)]
  // console.log('onlyIndependOptionsPrices', onlyIndependOptionsPrices)
  newPackage['isPriceDiff'] = isPriceDiff
  newPackage['onlyIndependOptionsPrices'] = onlyIndependOptionsPrices || null
  newPackage['onlydependOptionsPrices'] = onlydependOptionsPrices || null

  // 取得可獨立購買之票種所有天數之中最低價格 [EasonLiu]
  newPackage['packageMinPrices'] = !!onlyIndependOptionsPrices ? Object.values(onlyIndependOptionsPrices).reduce((acc, { price }) => {
    if (acc === 0 || (price !== 0 && price < acc)) acc = price
    return acc
  }, 0) : 0

  // 方案包含/不包含內容修改成 JSON 格式 [EasonLiu]
  if (newPackage.productItemSpecDesc?.speC_INC_NINC?.content){
    try {
      const incNincContent = JSON.parse(newPackage.productItemSpecDesc?.speC_INC_NINC?.content ?? '{}') 
      newPackage['packageIncNinc'] = {
        moduleTitle: newPackage.productItemSpecDesc?.speC_INC_NINC?.moduleTitle || '',
        content: {
          incs: incNincContent?.incs || '',
          nincs: incNincContent?.nincs || '',
        },
      }
    }
    catch (error){
      if (SHOW_CONSOLE) console.log('error', error)
    }
  }

  // 整理完後刪除 [EasonLiu]
  delete newPackage.productItemSpecDesc

  const checkShouldRemoveToday = () => { 
    if (newPackage.packageAllDates.length === 0) return
    const currentTime = dayjs()
    newPackage.currentTime = currentTime.format(DATETIME_FORMAT)
    const checkIsToday = (date) => date === currentTime.format(DATE_FORMAT)
    const checkOrderTime = () => {
      const timeStart = newPackage?.orderProcessTimeStart || '00:00'
      const timeEnd = newPackage?.orderProcessTimeEnd || '23:59'
      const startDateTime = dayjs(`${currentTime.format(DATE_FORMAT) }${timeStart}`)
      const endDateTime = dayjs(`${currentTime.format(DATE_FORMAT) }${timeEnd}`)
      newPackage.startDateTime = startDateTime.format(DATETIME_FORMAT)
      newPackage.endDateTime = endDateTime.format(DATETIME_FORMAT)
      return currentTime.isBetween(
        startDateTime, endDateTime, null, '[]',
      )
    }
    
    const checkOptionsOrderTime = (shouldRemovedDate) => {
      newPackage.productItemOptions = newPackage.productItemOptions.map(option => {
        const newDates = option.dates.filter(date => date !== shouldRemovedDate)
        delete option.optionDates[shouldRemovedDate]
        return {
          ...option,
          dates: newDates, 
        }
      })
    }
    
    const isFirstProductToday = checkIsToday(newPackage.packageAllDates[0])
    const isInOrderTime = checkOrderTime()

    if (isFirstProductToday && !isInOrderTime) {
      const shouldRemovedDate = currentTime.format(DATE_FORMAT)
      const indexToRemove = newPackage.packageAllDates.indexOf(shouldRemovedDate)
      if (indexToRemove !== -1) newPackage.packageAllDates.splice(indexToRemove, 1)
      delete newPackage.onlyIndependOptionsPrices[shouldRemovedDate]
      checkOptionsOrderTime(shouldRemovedDate)
    }

    /* 檢查販售日
     * 方案是否顯示"已售罄"，主要看 item.isAllSoldOut 是否為 true
     * 如果 detas 為 [] (無販售日期)，item.isAllSoldOut 將其設定為"已售罄" (2024-11-07 已與後端進行確認)
     * packageAllDates 包含所有 option[].dates，並檢查該日期是否小於今天
     * 當 packageAllDates 數量為 0，可以確定無銷售日期
    */
    if (newPackage.packageAllDates.length === 0) newPackage.isAllSoldOut = true

    newPackage.isInOrderTime = isInOrderTime
    newPackage.saleStartDate = newPackage.packageAllDates.length > 0 ? newPackage.packageAllDates[0] : null
  }
  
  checkShouldRemoveToday()

  return newPackage
}

export const getMetaData = (productInfo) => {
  if (Object.keys(productInfo).length === 0) return {}
  
  // 判斷是否為接送類商品 [EasonLiu]
  const isPickUpProduct = checkIsPickUpProduct(productInfo?.productName)
  const introduceSummaryContent = productInfo.productDesc?.introduction?.content || ''
  const moreInfoContent = productInfo.productDesc?.morE_INFO?.content || ''
  const noticesContent = productInfo.productDesc?.notice?.content || ''
  const checkExtraFeeContentArray = [
    introduceSummaryContent,
    moreInfoContent,
    noticesContent,
  ]
  const isExtraFeeProduct = checkExtraFeeContentArray.some(content => checkIsExtraFeeProduct(content)) 
  const productIntro = productInfo.productDesc.producT_INTRODUCTION.content
  const productIntroReformat = (isPickUpProduct || isExtraFeeProduct) ? productIntro.replace('</ul>', `<li easonclass="text-primary ${classReader({ ProductStyled: 'line-height-15' })}"><p easonclass="m-0">${DESC_FOR_TITLE}</p><p easonclass="m-0">${isPickUpProduct ? DESC_FOR_PICK_UP_PRODUCT : ''}</p><p easonclass="m-0">${isExtraFeeProduct ? DESC_FOR_EXTRA_FEE : ''}</p></li></ul>`) : productIntro
  productInfo
  return {
    /* SSR 優先處理資料 */
    productNumber: productInfo.number, // 商品編號
    productName: productInfo.productName || '', // 商品名稱
    productIntro: productIntroReformat || '', // 商品內容頁敘述(已修改) [EasonLiu]
    productIntroMenu: productInfo.productDesc.menU_INTRODUCTION.content || '', // 商品列表頁敘述(已修改) [EasonLiu]
    city: productInfo.city, // 先轉換成中文
    country: productInfo.country, // 先轉換成中文
    mediaList: productInfo.productMediaMap?.image, // 商品輪播圖
    isInstantBooking: productInfo.isInstantBooking ?? false, // 即買即用
    isAllSoldOut: productInfo.isAllSoldOut ?? false, // 全部已售罄
    isSaleByDate: productInfo.isSaleByDate ?? false, // 購買時需選擇日期
    price: productInfo.price || 0, // 商品原價
    minPrice: productInfo?.minPrice || 0, // 商品售價(台幣計價)
    saleMinPrice: productInfo.saleMinPrice || 0, // 商品特價(販促商品)
    hotaiPointType: productInfo.hotaiPointType || '',
    ecOrderLeadTime: productInfo.ecOrderLeadTime,

    /* CSR 處理資料 */
    originFormatData: productInfo, // 原始資料，用作進入頁面執行 kkDayProductConvert 資料處理
  }
}

export const kkDayProductConvert = (productInfo) => {
  if (Object.keys(productInfo).length === 0) return {}

  // 只取得必要資料，避免頁面載入過慢
  
  // 判斷是否為接送類商品 [EasonLiu]
  const isPickUpProduct = checkIsPickUpProduct(productInfo?.productName)
  const introduceSummaryContent = productInfo.productDesc?.introduction?.content || ''
  const moreInfoContent = productInfo.productDesc?.morE_INFO?.content || ''
  const noticesContent = productInfo.productDesc?.notice?.content || ''
  const checkExtraFeeContentArray = [
    introduceSummaryContent,
    moreInfoContent,
    noticesContent,
  ]
  const isExtraFeeProduct = checkExtraFeeContentArray.some(content => checkIsExtraFeeProduct(content)) 
  const productIntro = productInfo.productDesc.producT_INTRODUCTION.content
  const productIntroReformat = (isPickUpProduct || isExtraFeeProduct) ? productIntro.replace('</ul>', `<li easonclass="text-primary ${classReader({ ProductStyled: 'line-height-15' })}"><p easonclass="m-0">${DESC_FOR_TITLE}</p><p easonclass="m-0">${isPickUpProduct ? DESC_FOR_PICK_UP_PRODUCT : ''}</p><p easonclass="m-0">${isExtraFeeProduct ? DESC_FOR_EXTRA_FEE : ''}</p></li></ul>`) : productIntro

  return {
    // productId: productInfo.id, // 目前未使用
    productNumber: productInfo.number, // 商品編號
    productName: productInfo.productName || '', // 商品名稱
    productIntro: productIntroReformat || '', // 商品內容頁敘述(已修改) [EasonLiu]
    productIntroMenu: productInfo.productDesc.menU_INTRODUCTION.content || '', // 商品列表頁敘述(已修改) [EasonLiu]
    city: productInfo.city, // 先轉換成中文
    country: productInfo.country, // 先轉換成中文
    mediaList: productInfo.productMediaMap?.image, // 商品輪播圖
    isInstantBooking: productInfo.isInstantBooking ?? false, // 即買即用
    isAllSoldOut: productInfo.isAllSoldOut ?? false, // 全部已售罄
    isSaleByDate: productInfo.isSaleByDate ?? false, // 購買時需選擇日期
    price: productInfo.price || 0, // 商品原價
    minPrice: productInfo?.minPrice || 0, // 商品售價(台幣計價)
    saleMinPrice: productInfo.saleMinPrice || 0, // 商品特價(販促商品)
    hotaiPointType: productInfo.hotaiPointType || '',
    item: productInfo.item,
    ecOrderLeadTime: productInfo.ecOrderLeadTime,
    packages: productInfo.productItem.map((productPackage) => {
      // 預設
      productPackage.productItemOptionRules = []
      productPackage.productItemOptions = []
      productPackage.productItemSpecs = []

      const packageItem = productInfo.item
      const data = Array.isArray(packageItem) && packageItem.filter(e => e.productItemNumber === productPackage.number)[0]
      productPackage.hasOptionRules = data.hasOptionRules
      productPackage.productItemOptionRules = data.productItemOptionRules

      // productItemOptionRules 測試資料
      // productPackage.productItemOptionRules = [
      //   {
      //     'orderTokens': ['53d8c124ca91b62cb6d66c5e133fe346'],
      //     'minQuantity': 2,
      //     'maxQuantity': 8,
      //     'isMultipleLimit': true,
      //   },
      //   {
      //     'orderTokens': ['631757edbba862cd3837f0562df89560'],
      //     'minQuantity': 3,
      //     'maxQuantity': 10,
      //     'isMultipleLimit': true,
      //   },
      // ]
      
      productPackage.productItemOptions = data.productItemOptions
      productPackage.productItemSpecs = data.productItemSpecs

      return kkDayPackageConvert(
        productPackage, isPickUpProduct, isExtraFeeProduct,
      )
    }), // 商品方案(已修改) [EasonLiu]
    // #region 活動介紹區塊
    /* 文字介紹（已修改) [EasonLiu] */
    introduceSummary: {
      moduleTitle: introduceSummaryContent ? productInfo.productDesc?.introduction?.title : '',
      content: introduceSummaryContent,
    },
    /* 圖文介紹 */
    // graphic: {
    //   moduleTitle: productInfo.productDesc?.pmdLGraphic?.moduleTitle || '',
    //   content: productInfo.productDesc?.pmdLGraphic?.content?.list || [],
    // },
    /* 時程表 (目前未使用) */
    // schedule: {
    //   moduleTitle: productInfo.productDesc?.pmdLSchedule?.moduleTitle || '',
    //   content: {
    //     totalDay: productInfo.productDesc?.pmdLSchedule?.content?.totalDay?.desc || '',
    //     scheduleList: productInfo.productDesc?.pmdLSchedule?.content?.scheduleList?.list || [],
    //   },
    // },
    /* 建議路線 */
    // suggestedRoute: {
    //   moduleTitle: productInfo.productDesc?.pmdLSuggestedRoute?.moduleTitle || '',
    //   content: productInfo.productDesc?.pmdLSuggestedRoute?.content?.list || [],
    // },
    // #endregion 活動介紹區塊
    // #region 購買須知區塊
    /* 購買須知 */
    // purchaseSummary: {
    //   moduleTitle: productInfo.productDesc?.pmdLPurchaseSummary?.moduleTitle || '',
    //   content: productInfo.productDesc?.pmdLPurchaseSummary?.content.desc || '',
    // },
    /* 可使用國籍/地區 */
    // nationality: {
    //   moduleTitle: productInfo.productDesc?.pmdLNationality?.moduleTitle || '',
    //   content: {
    //     nationalityAllow: productInfo.productDesc?.pmdLNationality?.content?.properties?.nationalityAllow?.desc || '',
    //     nationalityNotAllow: productInfo.productDesc?.pmdLNationality?.content?.properties?.nationalityNotAllow?.desc || '',
    //     notes: productInfo.productDesc?.pmdLNationality?.content?.properties?.notes?.list || [],
    //   },
    // },
    /* 包含不包含 */
    // incNInc: {
    //   moduleTitle: productInfo.productDesc?.pmdLIncNinc?.moduleTitle || '',
    //   content: {
    //     incs:
    //       productInfo.productDesc.pmdLIncNinc?.content?.properties
    //         ?.includeItem?.list || [],
    //     nIncs:
    //       productInfo.productDesc.pmdLIncNinc?.content?.properties
    //         ?.notIncludeItem?.list || [],
    //   },
    // },
    /* 額外費用 */
    // extraFee: {
    //   moduleTitle: productInfo.productDesc?.pmdLExtraFee?.moduleTitle || '',
    //   content: productInfo.productDesc?.pmdLExtraFee?.content?.list || [],
    // },
    /* 注意事項（已修改) [EasonLiu] */
    notices: {
      moduleTitle: noticesContent ? productInfo.productDesc?.notice?.title : '',
      content: noticesContent,
      // content: {
      //   reminds: productInfo.productDesc?.pmdLNotice?.content?.properties
      //     ?.reminds?.list || [], // 注意事項
      //   custReminds: productInfo.productDesc?.pmdLNotice?.content?.properties
      //     ?.custReminds?.list || [], // 客製注意事項
      //   custRemindsAfter: productInfo.productDesc?.pmdLNotice?.content?.properties
      //     ?.custRemindsAfter?.list || [], // 客製售後注意事項
      // },
    },
    // #endregion 購買須知區塊
    // #region 如何使用區塊
    /* 如何使用（已修改) [EasonLiu] */
    howToSummary: {
      moduleTitle: productInfo.productDesc?.hoW_TO?.content ? productInfo.productDesc?.hoW_TO?.title : '',
      content: productInfo.productDesc?.hoW_TO?.content || '',
    },
    /* 如何兌換 */
    // exchange: {
    //   moduleTitle: productInfo.productDesc?.pmdLExchange?.moduleTitle || '',
    //   content: {
    //     desc: productInfo.productDesc?.pmdLExchange?.content?.properties?.exchangeType?.desc || '',
    //     caption: productInfo.productDesc?.pmdLExchange?.content?.properties?.description?.desc || '',
    //   },
    // },
    /* 兌換期限 */
    // exchangeValid: {
    //   moduleTitle: productInfo.productDesc?.pmdLExchangeValid?.moduleTitle || '',
    //   content: {
    //     exchange: productInfo.productDesc?.pmdLExchangeValid?.content?.properties?.exchange?.desc || '',
    //     expired: productInfo.productDesc?.pmdLExchangeValid?.content?.properties?.expired?.desc || '',
    //   },
    // },
    /* 使用期限 */
    // useValid: {
    //   moduleTitle: productInfo.productDesc?.pmdLUseValid?.moduleTitle || '',
    //   content: {
    //     exchange: productInfo.productDesc?.pmdLUseValid?.content?.properties?.exchange?.desc || '',
    //     expired: productInfo.productDesc?.pmdLUseValid?.content?.properties?.expired?.desc || '',
    //   },
    // },
    // #endregion 如何使用區塊
    // #region 兌換地點區塊
    // exchangeLocation: {
    //   moduleTitle: productInfo.productDesc?.pmdLExchangeLocation?.moduleTitle || '',
    //   content: productInfo.productDesc?.pmdLExchangeLocation?.content?.properties?.locations.list || [],
    // },
    // #endregion 兌換地點區塊
    // #region 集合地點區塊
    /* 集合地點 */
    // venueLocation: {
    //   moduleTitle: productInfo.productDesc?.pmdLVenueLocation?.moduleTitle || '',
    //   content: productInfo.productDesc?.pmdLVenueLocation?.content?.list || [],
    // },
    /* 體驗地點 */
    // experienceLocation: {
    //   moduleTitle: productInfo.productDesc?.pmdLExperienceLocation?.moduleTitle || '',
    //   content: productInfo.productDesc?.pmdLExperienceLocation?.content?.list || [],
    // },
    // #endregion  集合地點區塊
    // #region 更多消息（已修改成更多消息) [EasonLiu]
    moreInfo: {
      moduleTitle: moreInfoContent ? productInfo.productDesc?.morE_INFO?.title : '',
      content: moreInfoContent,
      // content: {
      //   policyType: productInfo.productDesc?.pmdLRefundPolicy?.content?.properties?.policyType?.desc || '', // 手續費收取方式
      //   partialRefund: productInfo.productDesc?.pmdLRefundPolicy?.content?.properties?.partialRefundList || [], // 政策內容
      // },
    },
    // #endregion 更多消息
  }
}

export const handleUniversalStudios = (data) => {
  let status = false
  if (UNIVERSAL_STUDIOS?.keyword.length > 0) {
    UNIVERSAL_STUDIOS.keyword.map(item => {
      if (!status) status = data?.includes(item)
    })
  }
  return status
}

export const checkOtherSpecs = (
  selectedPackage, selectedTitle, selectedId,
) => {
  const filterData = selectedPackage?.productItemOptions.filter((option) => option.specRefs.some(spec => spec.specValue === selectedId))
  const otherSpecs = 
     filterData.flatMap(option => option.specRefs)
       .filter(({ specId }) => specId !== selectedTitle)
       .reduce((acc, { specId, specValue }) => {
         if (!acc[specId]) {
           acc[specId] = []
         }
         if (!acc[specId].includes(specValue)) {
           acc[specId].push(specValue)
         }
         return acc
       }, {})
  
  return otherSpecs
}

export const filterUniversalStudiosCode = (data) => {
  return data.filter(e => Array.isArray(e?.props?.children) && !e?.props?.children?.includes(UNIVERSAL_STUDIOS.copyright))
}

export const getPageSSRData = (productInfo) => {
  
  let data = {
    meta: {
      title: '',
      description: '',
      url: '',
      canonical: '',
      img: [],
    },
    breadcrumbs: [],
    other: {
      country: '',
      city: '',
    },
  }

  if (Object.keys(productInfo).length === 0) return data

  const title = productInfo?.productName
  const productId = productInfo.number
  /* ------------------------------ 處理 meta 資料 Start ------------------------------ */
  // 商品標題
  data.meta.title = `${title}${TAB_TITLE_SUFFIX}`

  // 商品描述
  /* 
  * 決定是顯示內容，還是交給 google 決定
  * headDescription 會判斷以下條件
  * (1). pathProductInfo.productIntro
  * (2). pathProductInfo?.productIntroMenu
  * (3). pathProductInfo.introduceSummary.content
  * 如果三個都沒值，headDescription 回傳 ''，head og:description 不設定, 給 google 決定 
  */

  const introduceSummaryContent = productInfo.productDesc?.introduction?.content || ''
  const moreInfoContent = productInfo.productDesc?.morE_INFO?.content || ''
  const noticesContent = productInfo.productDesc?.notice?.content || ''
  
  const checkExtraFeeContentArray = [
    introduceSummaryContent,
    moreInfoContent,
    noticesContent,
  ]
  const isPickUpProduct = checkIsPickUpProduct(productInfo?.productName)
  const isExtraFeeProduct = checkExtraFeeContentArray.some(content => checkIsExtraFeeProduct(content)) 

  const productIntro = productInfo.productDesc.producT_INTRODUCTION.content
  const productIntroReformat = (isPickUpProduct || isExtraFeeProduct) ? productIntro.replace('</ul>', `<li easonclass="text-primary ${classReader({ ProductStyled: 'line-height-15' })}"><p easonclass="m-0">${DESC_FOR_TITLE}</p><p easonclass="m-0">${isPickUpProduct ? DESC_FOR_PICK_UP_PRODUCT : ''}</p><p easonclass="m-0">${isExtraFeeProduct ? DESC_FOR_EXTRA_FEE : ''}</p></li></ul>`) : productIntro

  data.meta.description = productIntroReformat || ''

  // 商品列表頁敘述
  if (data.meta.description === '') {
    data.meta.description = productInfo.productDesc.menU_INTRODUCTION.content || ''
  }

  // 文字介紹
  if (data.meta.description === '') {
    data.meta.description = productInfo.productDesc?.introduction?.content || '' 
  }

  data.meta.url = `${ENV_INFO.root}product/${productId}`
  data.meta.canonical = data.meta.url
  data.meta.img = Array.isArray(productInfo.productMediaMap.image) && productInfo.productMediaMap.image.length > 0 ? productInfo.productMediaMap.image : []

  // 針對資料進行過濾
  data.meta.title = sanitizeForHead(data.meta.title, 180)
  data.meta.description = sanitizeForHead(data.meta.description, 180)


  /* ------------------------------ 處理 meta 資料 End ------------------------------ */

  data.other.country = COUNTRY_DATA[productInfo.country] || '' // 商品內容頁敘述

  let productCityLabel = []
  let productCityId = []
  if (productInfo?.city && productInfo?.city.length > 0) {
    productInfo.city.map( cityId => {
      if (CITY_DATA.hasOwnProperty(cityId) && !productCityLabel.includes(CITY_DATA[cityId])) {
        productCityLabel.push(CITY_DATA[cityId])
        productCityId.push(cityId)
      }
    })
  } 
  data.other.city = productCityLabel.join('、')

  data.breadcrumbs.push({
    link: ENV_INFO.root,
    label: '首頁',
    id: 'breadcrumb-home',
  })

  data.breadcrumbs.push({
    link: `${ENV_INFO.root}search?location=${productCityId.join(',')}`,
    label: ( data.other.country && data.other.city) ? `${ data.other.country } ${data.other.city}` : '推薦商品',
    id: 'breadcrumb-search',
  })

  data.breadcrumbs.push({
    link: data.meta.url,
    label: title,
    id: 'breadcrumb-product',
  })

  return data
}