import {
  memo, useEffect, useState, 
} from 'react'
import PropTypes from 'prop-types'
import classReader from 'utils/classReader'

const AircraftRunwayAnimate = (props) => {
  const { 
    id,
    className,
    airplane,
    shadow,
  } = props
  return (<>
    <div id={id} className={classReader({ AircraftRunwayStyled: 'airplane-takeoff-and-landing' }, className)}>
      <div className={classReader({ AircraftRunwayStyled: 'airplane-takeoff-and-landing-animation' })} data-shadow={shadow} data-airplane-direction={airplane}>
        <div className={classReader({ AircraftRunwayStyled: 'airplane-takeoff-and-landing-animation__runway' })} data-runway-direction={ airplane === 'right' ? 'left' : 'right'}></div>
      </div>
    </div>
  </>)
}


AircraftRunwayAnimate.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  airplane: PropTypes.string,
  shadow: PropTypes.bool,
}
AircraftRunwayAnimate.defaultProps = {
  id: '',
  className: '',
  airplane: 'right',
  shadow: false,
}

export default memo(AircraftRunwayAnimate)
