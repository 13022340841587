/*
 * 監聽時間
 * 用於 /utils/timoerManager.js
 * INTERVAL_TIME: 設定多久監聽一次
 * 
 * INTERVAL_MINUTE: 設定幾分鐘
 * INTERVAL_SECONDS: 設定幾秒(基數)，預設60，代表60秒，方便分鐘加乘
 */
const INTERVAL_MINUTE = 1
const INTERVAL_SECONDS = 60
export const INTERVAL_TIME = INTERVAL_MINUTE * INTERVAL_SECONDS

// 剩餘登入時間小於 N 分鐘 (m)
export const REMAINING_LOGIN_TIME = 10 

// 登入時間滿 N 分鐘 (m)
export const LOGIN_DURATION = 20

/*
 * 參數說明:
 * nbf: 生效時間
 * exp: 過期時間
 * iat: 簽發時間
*/
export const REFRESH_TOKEN_SWITCH = 'nbf'

// 登入時長超過參數列舉
export const LOGIN_TIME_EXCEEDS_PARAMETER_LIST = ['nbf', 'iat']