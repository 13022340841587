import { createSlice } from '@reduxjs/toolkit'

import {
  ERROR_CODE_LABEL,
  OAUTH_ERROR_CODE_LABEL,
  CART_ERROR_CODE_LABEL,
  OAUTH_LOGIN_REDIRECT_ERROR_CODE,
  REFRESH_TOKEN_ERROR_CODE,
} from 'config/error'

const errorInitial = {
  errorStatus: '',
  errorCode: '',
  errorMessage: '',
  hasRefreshToken: false,
  hasRedirectOAuthLogin: false,
}

export const errorSlice = createSlice({
  name: 'error',
  initialState: errorInitial,
  reducers: {
    setErrorState(state, action) {
      state.errorStatus = action?.payload?.status
      state.errorCode = action?.payload?.code

      if (OAUTH_LOGIN_REDIRECT_ERROR_CODE.includes(action.payload.code)) {
        state.hasRedirectOAuthLogin = true
      } else if (REFRESH_TOKEN_ERROR_CODE.includes(action.payload.code)) {

        if (state.hasRefreshToken === false) {
          state.hasRefreshToken = true
        } else {
          state.hasRedirectOAuthLogin = true
          state.hasRefreshToken = false
        }
      } else if (action.payload?.statusCode !== undefined) {
        // apim 無 api 時將回應以下格式 
        state.errorCode = action.payload.statusCode
        state.errorMessage = action.payload.message
      } else if (action.payload.code === undefined && action.payload?.messages) {
        /*
         * 新增 API (新格式,於2024年初由後端統一定義)回應資料格式
         * 交易失敗 code 將會 3XXX
         * 加入購物車/購買失敗，code 列舉於 CART_ERROR_CODE_LABEL
         */ 
        state.errorCode = action.payload.messages[0]?.errorNo
        state.errorMessage = Object.keys(CART_ERROR_CODE_LABEL).includes(state.errorCode) ? CART_ERROR_CODE_LABEL[state.errorCode] : action.payload.messages[0]?.message[0]?.message
      } else {
        state.errorMessage = action.payload.message
        state.hasRedirectOAuthLogin = false
        state.hasRefreshToken = false
      }
    },
    setHasRedirectOAuthLoginState(state, action) {
      state.hasRedirectOAuthLogin = action.payload
    },
    resetErrorState() {
      return errorInitial
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('auth/ecVerifyToken/rejected', (state, action) => {
        if (action.payload.status !== 500) {
          state.hasRefreshToken = true
          state.hasRedirectOAuthLogin = false
        }
      })
      .addCase('auth/ecRefreshToken/rejected', (state) => {
        state.hasRefreshToken = false
        state.hasRedirectOAuthLogin = true
      })
      .addCase('cart/addCartProduct/rejected', (state, action) => {
        state.hasRefreshToken = false
        if (REFRESH_TOKEN_ERROR_CODE.includes(action?.payload?.code)) {
          state.hasRedirectOAuthLogin = true
        } else {
          state.errorMessage = action?.payload?.message
          state.hasRedirectOAuthLogin = false
        }
      })
      .addMatcher((action) => {
        return action.type.endsWith('/fulfilled') &&
            (
              action.type.startsWith('auth/ecRefreshToken') ||
              action.type.startsWith('auth/ecSignIn') ||
              action.type.startsWith('auth/ecSignOut')
            )
      },
      () => errorInitial)
      .addMatcher((action) => {
        return action.type.endsWith('/rejected') &&
            (
              action.type.startsWith('localAuth/ecLocalSignIn') ||
              action.type.startsWith('oAuth/oAuthSignIn') ||
              action.type.startsWith('auth/ecSignIn')
            )
      },
      (state, action) => (
        {
          ...errorInitial,
          errorStatus: action.payload.status || 500,
          errorCode: action.payload.code || 9999,
          errorMessage: action.payload?.message || OAUTH_ERROR_CODE_LABEL[8000],
        }
      ))
      .addMatcher((action) => {
        (
          action.type.startsWith('auth/resetAuthSetting') ||
            action.type.startsWith('auth/ecSignOut/rejected')
        )
      },
      () => errorInitial)
  },
})

const { actions, reducer } = errorSlice

export const {
  setErrorState,
  resetErrorState,
} = actions
export const selectErrorStatus = (state) => state.error.errorStatus
export const selectErrorCode = (state) => state.error.errorCode
export const selectErrorMessage = (state) => state.error.errorMessage
export const selectHasRefreshToken = (state) => state.error.hasRefreshToken
export const selectHasRedirectOAuthLogin = (state) => state.error.hasRedirectOAuthLogin

export default reducer