import {
  useState,
  useEffect,
  memo,
} from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import classReader from 'utils/classReader'
import ReactLoading from 'react-loading'
import { useDebouncedCallback } from 'use-debounce'

import { moneyFormat, decodeJwt } from 'utils/util'
import {
  IS_DEVELOPMENT_ENV,
  AUTH_PAGE,
  ENV_INFO,
  SHOW_CONSOLE, 
  LOCAL_STORAGE,
} from 'config/config'
import {
  searchHistory,
  HOT_ADDRESS,
} from 'config/header'
import { FOOTER_NAV } from 'config/footer'
import { WEB_TITLE } from 'config/head'
import { COLOR_MAPPING } from 'config/style'
import { ACCOUNT_MENU_LIST } from 'config/page/account'
import auth from 'apis/auth/auth'
import exchange from 'apis/auth/exchange'
import product from 'apis/product/product'
import { useAuth } from 'hooks/useAuth'
import { useWindowSize } from 'hooks/useWindowSize'
import { selectAuthRedirectUri } from 'slices/hotai/oAuthSlice'

import TopRecommendLikes from 'common/layout/TopRecommendLikes'
import SearchBar from 'common/layout/SearchBar'
import Menu from 'common/layout/mobile/Menu'
import MobileSearchBar from 'common/layout/mobile/SearchBar'
import MenuPopup from 'common/layout/mobile/MenuPopup'
import Button from 'common/Button'
import Modal from 'common/Modal'

import Image from 'next/image'
import ChictripApp from 'public/chictrip-modal.svg'
import ChictripQRcode from 'public/chictrip-qrcode.png'
import AppleStroe from 'public/apple-stroe.svg'
import GooglePlay from 'public/google-play.svg'
import dayjs from 'dayjs'
import {
  REFRESH_TOKEN_SWITCH,
  REMAINING_LOGIN_TIME,
  LOGIN_DURATION,
  LOGIN_TIME_EXCEEDS_PARAMETER_LIST,
} from 'config/token'

const Header = ({
  handleRefreshToken,
  handleRefreshTokenResult, 
}) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const authRedirectUri = useSelector(selectAuthRedirectUri)
  const {
    accountLogin,
    accountLocalLogin, // NOTE: for local login
    accountLogout,
    accessToken,
    userName,
    userHotaiPoint,
    userCartAmount,
    isTokenVerifying,
    isExchanging,
  } = useAuth()
  const { windowWidthSize } = useWindowSize()

  const [searchKeyword, setSearchKeyword] = useState('')
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isOpenSearch, setIsOpenSearch] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [productList, setProductList] = useState([])
  const [isDialog, setIsDialog] = useState(false)
  const [prevPage, setPrevPage] = useState(false)
  const [queryCode, setQueryCode] = useState(false)

  // 開啟漢堡選單
  // 漢堡選單開啟時應該顯示熱門活動分類，和熱門推薦(日本、泰國、台南特輯、熱門遊程、熱門國家、熱門城市)
  // 漢堡選單在首頁時隱藏，首頁以外的地方才顯示
  // 分兩個步驟:
  // 1. 首頁隱藏漢堡選單,其他頁面顯示 v
  // 2. 漢堡選單刻板(資料結構確認)
  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isOpenCategoryMenu, setIsOpenCategoryMenu] = useState(false)

  const searchProduct = useDebouncedCallback(async (newKeyword) => {
    setIsSearching(true)
    try {
      if (newKeyword === '') {
        setProductList([])
      } else {
        const result = await product.searchBarProduct(newKeyword)
        const { data } = result

        setProductList(data.products)
      }
    } catch (error) {
      setProductList([])
    } finally {
      setIsSearching(false)
    }
  }, 500)

  const atExchangeOut = async () => {
    if (Boolean(accessToken)) {
      try {
        const result = await auth.ecExchangeOut({ toPlatform: 'HTPOS' })
        const { data } = result
        exchange.exchangeOutHTPOS(data.token)
      } catch (error) {
        router.push(ENV_INFO.HTExchangeUrl)
      }
    } else {
      // 改為由ENV設定
      router.push(ENV_INFO.hotaiGoUrl)
    }
  }

  const atHandleLogin = () => {
    if (isOpenMenu) setIsOpenMenu(false)
    /*
     * 說明：
     * 早期版本有配合 localhost 開發
     * 設計專用端登入 accountLocalLogin，該版本於 2023 第三季之前
     * accountLocalLogin 需搭配後端開放測試專用 API(後端目前已關閉 or 刪除)
     * 
     * 於 2023 年底
     * 系統登入 - 會員資料驗證
     * 由 EC 自己的會員資料，全面更換使用【和泰集團會員中心】
     * 全面更換使用【和泰集團會員中心】
     * 
     * 
     * 該項為【和泰汽車集團】規範
     * 用意：
     * 為使集團用戶，可統一使用 ondID(和泰集團 對於集團用戶 ID 的官方稱呼) 進行追蹤
     * 進而累計用戶數據，以達到能分析用戶輪廓，並最終進行精準行銷
     * 
     * 關係企業需將相關登入系統統一進行更換，需於2023年內完成。
     * 
     * 故，accountLocalLogin 於現行版本中，並未用到
     */ 
    // IS_DEVELOPMENT_ENV ? accountLocalLogin() : accountLogin()

    accountLogin()
  }

  const atHandleLogout = () => {
    if (isOpenMenu) setIsOpenMenu(false)
    accountLogout()
  }

  const atToggleMenu = () => {
    setIsOpenMenu(prev => !prev)
  }

  const atCloseMenu = () => {
    setIsOpenMenu(false)
  }

  const atToggleSearch = () => {
    setIsOpenSearch(prev => !prev)
  }

  const atCloseSearch = () => {
    setIsOpenSearch(false)
  }

  const atToggleCategoryMenu = () => {
    setIsOpenCategoryMenu(prev => !prev)
  }

  const atCloseCategoryMenu = () => {
    setIsOpenCategoryMenu(false)
  }

  useEffect(() => {
    console.log('isOpenCategoryMenu', isOpenCategoryMenu)
    
  }, [isOpenCategoryMenu])

  const atSubmitSearch = () => {
    router.push({
      pathname: '/search',
      query: { keyword: searchKeyword },
    })
    setIsOpenSearch(false)
  }

  // 執行 refresh token
  const handleGotoCartRefreshToken = async () => {
    if (SHOW_CONSOLE) console.log('前往購物車前，先換 token')
    dispatch({ type: 'loading/openLoading' })
    try {
      const result = await handleRefreshToken()
      if (!result?.success) throw {}
      handleRefreshTokenResult(true, result.data)
    } catch (error) {
      handleRefreshTokenResult(false)
      atHandleLogin()
    } finally {
      dispatch({ type: 'loading/closeLoading' })
      router.push('/cart')
    }
  }

  // 處理 點選購物車
  const handleClickShoppingCart = async () => {
    if (SHOW_CONSOLE) console.log('優先判斷是否有 token')
    if (Boolean(accessToken)) {
      const diffInMinutes = dayjs().diff(dayjs( decodeJwt(accessToken)[REFRESH_TOKEN_SWITCH] * 1000 ), 'minute')
      if (diffInMinutes >= LOGIN_DURATION) handleGotoCartRefreshToken()
      else router.push('/cart')
    } else {
      // 沒有 accessToken，視為未登入
      atHandleLogin()
    }
  }

  // const handlePrevPage = () => {
  //   history.back()
  // }

  useEffect(() => {
    if (isLoggedIn === Boolean(accessToken)) return
    setIsLoggedIn(Boolean(accessToken))
  }, [accessToken, isLoggedIn])

  useEffect(() => {
    if (!!authRedirectUri) router.push(authRedirectUri)
    /* ignore dependency: router */
  }, [authRedirectUri])

  useEffect(() => {
    if (windowWidthSize > 991 && isOpenMenu) atToggleMenu()
  }, [windowWidthSize, isOpenMenu])

  useEffect(() => {
    const { keyword = '' } = router.query || {}

    if (router.pathname !== '/') {
      setPrevPage(true)

      setIsShowMenu(true) // 顯示漢堡選單
    } else {
      setSearchKeyword(router.pathname === 'search' ? keyword : '')
      setPrevPage(false)

      setIsShowMenu(false) // 隱藏漢堡選單
    }
  }, [router.pathname])

  useEffect(() => {
    const { keyword } = router.query
    setSearchKeyword(keyword === undefined ? '' : keyword)
  }, [router.query])

  useEffect(() => {
    if (router.isReady) {
      setQueryCode( Boolean(userName) ? false : Boolean(router?.query?.code) )
    }
  }, [router])

  useEffect(() => {
    if (searchKeyword.trim()) searchProduct(searchKeyword)
  }, [searchKeyword])

  useEffect(() => {
    // console.log('userName', userName)
  }, [userName])

  const isUserVerify = (isTokenVerifying || isLoggedIn || isExchanging || queryCode) && Boolean(userName) === false
  
  const isHideSearchBlock = Boolean(AUTH_PAGE.find(page => router.pathname.includes(page)))

  return (
    <>
      <header className={classReader('header')}>
        <div className={classReader('header-body')}>
          <div className={classReader('container')}>
            <div className={classReader('d-flex justify-content-between')}>
              <div className={classReader('header__content')}>
                <ul>
                  {/* <li>
                    {isShowMenu && <Button
                      className={classReader('btn--unelevated p-0 d-lg-none border border-primary', { pure: 'gtm-header-menu' })}
                      icon="menu"
                      closeAnimate
                      onClick={atToggleCategoryMenu}
                      // onClick={atToggleMenu}
                      ariaLabel="Menu"
                    />}
      
                  </li> */}
                  <li className={classReader('pr-3')}>
                    <Link prefetch={false} href="/" aria-label={WEB_TITLE} passHref>
                      <i className={classReader('icon icon-chicTrip', { pure: 'gtm-icon-chicTrip' })} />
                      <span className={classReader('d-none')}>{WEB_TITLE}</span>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className={classReader('header__content header__content--center d-none d-lg-block')}>
                <SearchBar
                  keyword={searchKeyword}
                  hotAddress={HOT_ADDRESS}
                  searchHistory={searchHistory}
                  searchResult={productList}
                  isSearching={isSearching}
                  onChangeKeyword={setSearchKeyword}
                  onSubmitSearch={atSubmitSearch}
                  onClosePopup={atCloseMenu}
                />
              </div>

              <div className={classReader('header__content header__content--right', { pure: 'gtm-header-content' })}>
                <ul>
                  <li
                    className={classReader('ml-3 d-none d-lg-block cursor-pointer')}
                    onClick={() => setIsDialog(prev => !prev)}
                  >
                    旅遊規劃<span>必備APP</span>
                  </li>

                  <li className={classReader('ml-3 ml-lg-2')}>
                    {isUserVerify && (
                      <ReactLoading
                        type="spin"
                        color={COLOR_MAPPING.primary}
                        height={20}
                        width={20}
                      />
                    )}
                    {!isUserVerify && <>
                      {isLoggedIn ? (
                        <>
                          <div className={classReader('header__login d-flex align-items-center text-primary')}>
                            <i className={classReader('icon icon-user icon-primary d-lg-none')} onClick={() => atToggleMenu()} />
                            <i className={classReader('icon icon-user icon-primary d-none d-lg-block')} />
                            <span className={classReader('d-none d-lg-block cursor-default')}>
                              {userName || ''}
                            </span>
                          </div>

                          <div className={classReader('header__menu')}>
                            <div className={classReader('header__menu__content')}>
                              <div className={classReader('header__menu__point d-flex align-items-center border-bottom h4')}>
                                <i className={classReader('icon icon-point icon-white bg-red rounded-100 mr-2')} />
                                <span className={classReader('text-red')}>{moneyFormat(userHotaiPoint)}</span> 點
                              </div>
                              <div>
                                {ACCOUNT_MENU_LIST.map((item, index) => item.disabled === false && (
                                  <Link
                                    className={classReader({ pure: 'gtm-header-menu-item' })}
                                    key={index}
                                    prefetch={false}
                                    href={item.link}
                                    aria-label={item.label}
                                    target={item.target}
                                    passHref
                                  >
                                    <div className={classReader('d-flex align-items-center mb-2 p-1')}>
                                      <i className={classReader('icon icon-sm icon-primary mr-2', `icon-${item.icon}`)} />
                                      {item.label}
                                    </div>
                                  </Link>
                                ))}
                                <Link
                                  prefetch={false}
                                  href="#"
                                  className={classReader('d-block')}
                                  aria-label="登出"
                                  onClick={atHandleLogout}
                                  passHref
                                >
                                  <div className={classReader('d-flex align-items-center mb-2 p-1')}>
                                    <i className={classReader('icon icon-logout icon-sm icon-primary mr-2')} />
                                  登出
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <i className={classReader('icon icon-user d-lg-none cursor-pointer', { pure: 'gtm-mobile-header-login' })} onClick={() => atToggleMenu()} />
                          <span className={classReader('d-none d-lg-block cursor-pointer')} onClick={() => atHandleLogin()}>
                          登入/註冊
                          </span>
                        </>
                      )}
                    </>}
                    
                  </li>

                  <li className={classReader('ml-2 ml-lg-2 relative-position')}>
                    <div className={classReader({ pure: 'gtm-shopping-cart' }, 'cursor-pointer')} aria-label="購物車">
                      { Boolean(accessToken) && Boolean(userCartAmount) && (
                        <small className={classReader('header__shopping-cart-quantity absolute')}>{userCartAmount}</small>
                      )}
                      <i className={classReader('icon icon-shopping-cart')} onClick={() => handleClickShoppingCart()}/>
                      <span className={classReader('d-none')}>購物車</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            {isHideSearchBlock === false && (
              <>
                <MobileSearchBar
                  keyword={searchKeyword}
                  prompt={isOpenSearch}
                  hotAddress={HOT_ADDRESS}
                  searchHistory={searchHistory}
                  searchResult={productList}
                  isSearching={isSearching}
                  onToggleSearch={atToggleSearch}
                  onClosePopup={atCloseSearch}
                  onChangeKeyword={setSearchKeyword}
                  onSubmitSearch={atSubmitSearch}
                />

                <TopRecommendLikes />
              </>
            )}
          </div>
        </div>

        <MenuPopup
          id="header-menu"
          className="mobile-menu-popup--height200"
          prompt={isOpenMenu}
          onTogglePopup={atToggleMenu}
          onClosePopup={atCloseMenu}
          rounded
        >
          <Menu
            navAccount={ACCOUNT_MENU_LIST}
            navMenu={FOOTER_NAV}
            isLoggedIn={isLoggedIn}
            userData={{
              name: userName,
              point: userHotaiPoint,
            }}
            onToggleMenu={atToggleMenu}
            onHandleLogin={() => atHandleLogin()}
            onHandleLogout={atHandleLogout}
            onExchangeOut={atExchangeOut}
            setIsDialog={setIsDialog}
          />
        </MenuPopup>
      </header>

      <Modal
        prompt={isDialog}
        className={classReader('header__app')}
      >
        <div className={classReader('text-center p-3')}>
          <div className={classReader('text-end')}>
            <i
              className={classReader('icon icon-close icon-gray-3 cursor-pointer')}
              onClick={() => setIsDialog(prev => !prev)}
            ></i>
          </div>
          <Image
            className={classReader('header__app__image')}
            width={289}
            height={253}
            alt=""
            src={ChictripApp}
            priority
          />
          <div className={classReader('d-flex justify-content-center align-items-center')}>
            <div className={classReader('p-2 text-left')}>
              <i className={classReader('icon icon-chicTrip')} />
              <p className={classReader('m-0 pt-1 pb-2 header__app__standard-main')}><b>旅遊規劃必備APP</b></p>
              <p className={classReader('m-0 pt-1 header__app__standard-sub')}>美好旅程 即刻啟程</p>
            </div>
            <div className={classReader('p-2')}>
              <Image
                className={classReader('header__app__qrcode')}
                width={120}
                height={120}
                alt=""
                src={ChictripQRcode}
                priority
              />
            </div>
          </div>
          <div className={classReader('header__app__download pd-2 mt-2 mb-2')}>
            <Link
              className={classReader({ pure: 'gtm-download-app' })}
              prefetch={false} href="https://play.google.com/store/apps/details?id=com.hotaimotor.chicTrip&hl=zh-TW"
              aria-label="Google Play Download"
              passHref>
              <Image
                width={'50%'}
                height={40}
                alt=""
                src={GooglePlay}
                priority
              />
            </Link>

            <Link
              className={classReader({ pure: 'gtm-download-app' })}
              prefetch={false}
              href="https://apps.apple.com/tw/app/%E5%8E%BB%E8%B6%A3-chictrip/id6443825385?l=en"
              aria-label="Apple Stroe Download"
              passHref>
              <Image
                width={'50%'}
                height={40}
                alt=""
                src={AppleStroe}
                priority
              />
            </Link>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default memo(Header)