export const DESC_FOR_TITLE = '請注意：'
export const DESC_FOR_PICK_UP_PRODUCT = '**最終接駁時間會於出發前一日晚間7點提供'
export const DESC_FOR_EXTRA_FEE = '**商品需額外收費部分'
export const PICK_UP_PRODUCT_KEYWORDS = [
  '接送',
  '接駁',
  '接機',
  '一日遊',
  '半日遊',
  '包團',
]

export const PRODUCT_INFO_TAB = [
  {
    name: 'activity',
    label: '活動介紹',
    id: 'product-menu-activity',
    show: (value) => (
      Boolean(value.introduceSummary?.moduleTitle) ||
      Boolean(value.graphic?.moduleTitle) ||
      Boolean(value.schedule?.moduleTitle) ||
      Boolean(value.suggestedRoute?.moduleTitle)
    ),
  },
  {
    name: 'notices',
    label: '注意事項',
    id: 'product-menu-notice',
    show: (value) => (
      Boolean(value.purchaseSummary?.moduleTitle) ||
      Boolean(value.notices?.moduleTitle) ||
      Boolean(value.incNInc?.moduleTitle) ||
      Boolean(value.incNInc?.moduleTitle)
    ),
  },
  {
    name: 'use',
    label: '如何使用',
    id: 'product-menu-use',
    show: (value) => (
      Boolean(value.howToSummary?.moduleTitle) ||
      Boolean(value.exchange?.moduleTitle) ||
      Boolean(value.useValid?.moduleTitle) ||
      Boolean(value.exchangeValid?.moduleTitle)
    ),
  },
  {
    name: 'exchangeLocation',
    label: '兌換地點',
    id: 'product-menu-exchange-location',
    show: (value) => Boolean(value.exchangeLocation?.moduleTitle),
  },
  {
    name: 'location',
    label: '集合地點',
    id: 'product-menu-location',
    show: (value) => (
      Boolean(value.venueLocation?.moduleTitle) ||
      Boolean(value.experienceLocation?.moduleTitle)
    ),
  },
  {
    name: 'moreInfo',
    label: '更多消息',
    id: 'product-menu-cancel',
    show: (value) => Boolean(value.moreInfo?.moduleTitle),
  },
]

// 退款金額或比例(取決於 RefundPolicy 如果是 REFUNDWITHFEE 則退款金額需參考 RefundRules 欄位，大於0為金額小於0為百分比)
export const REFUND_POLICY_DESC = {
  'FULL': '免費取消 !',
  'NONEREFUND': '商品一經訂購完成後，即不可取消、更改訂單，亦不得請求退款',
  'REFUNDWITHFEE': '需收取手續費',
}

export const UNIVERSAL_STUDIOS = {
  keyword: ['環球影城門票'],
  code: 'CR23-4768',
  copyright: 'TM & © Universal Studios & AmblinEntertainmentTM & © Universal Studios. All rightsreserved.',
}

// 商品 package Spec 選項無效備註列舉
export const PACKAGE_SPEC_INVALID_REMARKS = [
  '',
  '()',
  '[]',
  '{}',
  '（）',
  '［］',
  '｛｝',
  '「」',
  '﹙﹚',
  '『』',
  '【】',
]