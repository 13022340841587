import PopularAttractionsType01 from 'public/banner/popular-activities/type_01.jpg'
import PopularAttractionsType02 from 'public/banner/popular-activities/type_02.jpg'
import PopularAttractionsType03 from 'public/banner/popular-activities/type_03-3.jpg'
import PopularAttractionsType04 from 'public/banner/popular-activities/type_04.jpg'
import PopularAttractionsType05 from 'public/banner/popular-activities/type_05.jpg'
import PopularAttractionsType06 from 'public/banner/popular-activities/type_06.jpg'
import PopularAttractionsType07 from 'public/banner/popular-activities/type_07.jpg'
import PopularAttractionsType08 from 'public/banner/popular-activities/type_08.jpg'

export const APPELLATION_OPTIONS = [
  {
    value: 1,
    label: '先生',
  },
  {
    value: 2,
    label: '小姐',
  },
]

export const PRODUCT_GROUP = [
  {
    label: '一日遊',
    value: 'M01',
  },
  {
    label: '多日遊',
    value: 'M02',
  },
  {
    label: '機場接送',
    value: 'M03',
  },
  {
    label: '司機/交通',
    value: 'M04',
  },
  {
    label: '票券',
    value: 'M05',
  },
  {
    label: '半日遊',
    value: 'M06',
  },
  {
    label: '私人導遊',
    value: 'M07',
  },
  {
    label: '點對點接送',
    value: 'M08',
  },
]


export const THEME_OPTIONS = [
  {
    label: '美食甜點',
    img: PopularAttractionsType04,
    alt: '美食甜點',
    icon: 'home-icon/v2/dessert.svg',
    value: 1,
    tags: [
      '0205',
      '0302',
      '1300',
    ],
    link: '/search?theme=1',
  },
  {
    label: '景點門票',
    img: PopularAttractionsType06,
    alt: '景點門票',
    icon: 'home-icon/v2/ticket.svg',
    value: 2,
    tags: ['0201', '0200'],
    link: '/search?theme=2',
  },
  {
    label: '溫泉泡湯',
    img: PopularAttractionsType07,
    alt: '溫泉泡湯',
    icon: 'home-icon/v2/spa.svg',
    value: 3,
    tags: ['0800'],
    link: '/search?theme=3',
  },
  {
    label: '博物/美術館',
    img: PopularAttractionsType03,
    alt: '博物/美術館',
    icon: 'home-icon/v2/aquarium.svg',
    value: 4,
    tags: [
      '0100',
      '0600',
      '0202',
    ],
    link: '/search?theme=4',
  },
  {
    label: '工作坊＆課程',
    img: PopularAttractionsType02,
    alt: '工作坊＆課程',
    icon: 'home-icon/v2/lesson.svg',
    value: 5,
    tags: [
      '0304',
      '1000',
      '0900',
    ],
    link: '/search?theme=5',
  },
  {
    label: '活動 & 展覽',
    img: PopularAttractionsType05,
    alt: '活動 & 展覽',
    icon: 'home-icon/v2/art.svg',
    value: 6,
    tags: ['0300', '0206'],
    link: '/search?theme=6',
  },
  {
    label: '主題樂園',
    img: PopularAttractionsType08,
    alt: '主題樂園',
    icon: 'home-icon/v2/amusement.svg',
    value: 7,
    tags: ['0203'],
    link: '/search?theme=7',
  },
  {
    label: 'spa & 按摩',
    img: PopularAttractionsType01,
    alt: 'spa & 按摩',
    icon: 'home-icon/v2/massage.svg',
    value: 8,
    tags: ['0303', '0700'],
    link: '/search?theme=8',
  },
  {
    label: '住宿',
    img: '',
    alt: '住宿',
    icon: '',
    value: 9,
    tags: [
      '1400',
      '0601',
      '0602',
      '0603',
    ],
    link: '/search?theme=9',
  },
  {
    label: '觀光行程',
    img: '',
    alt: '觀光行程',
    icon: '',
    value: 10,
    tags: [
      '0504',
      '0404',
      '0401',
      '0301',
      '0402',
      '0403',
      '0405',
      '1100',
      '1200',
      '0305',
      '0306',
    ],
    link: '/search?theme=10',
  },
  {
    label: '戶外活動',
    img: '',
    alt: '戶外活動',
    icon: '',
    value: 11,
    tags: [
      '0604',
      '0102',
      '0103',
      '0101',
      '0204',
      '0400',
      '0407',
      '0500',
    ],
    link: '/search?theme=11',
  },
]

export const COUNTRY_MAP = [
  {
    label: '台灣',
    value: 'TW',
  },
  {
    label: '日本',
    value: 'JP',
  },
  {
    label: '韓國',
    value: 'KR',
  },
  {
    label: '泰國',
    value: 'TH',
  },
  {
    label: '港澳',
    value: 'CN',
  },
  {
    label: '阿聯',
    value: 'AE',
  },
  {
    label: '澳洲',
    value: 'AU',
  },
  {
    label: '巴西',
    value: 'BR',
  },
  {
    label: '加拿大',
    value: 'CA',
  },
  {
    label: '瑞士',
    value: 'CH',
  },
  {
    label: '德國',
    value: 'DE',
  },
  {
    label: '西班牙',
    value: 'ES',
  },
  {
    label: '法國',
    value: 'FR',
  },
  {
    label: '匈牙利',
    value: 'HU',
  },
  {
    label: '印尼',
    value: 'ID',
  },
  {
    label: '馬來西亞',
    value: 'MY',
  },
  {
    label: '紐西蘭',
    value: 'NZ',
  },
  {
    label: '菲律賓',
    value: 'PH',
  },
  {
    label: '帛琉',
    value: 'PW',
  },
  {
    label: '新加坡',
    value: 'SG',
  },
  {
    label: '土耳其',
    value: 'TR',
  },
  {
    label: '英國',
    value: 'UK',
  },
  {
    label: '美國',
    value: 'US',
  },
  {
    label: '越南',
    value: 'VN',
  },
  {
    label: '葡萄牙',
    value: 'PT',
  },
  {
    label: '挪威',
    value: 'NO',
  },
  {
    label: '荷蘭',
    value: 'NL',
  },
  {
    label: '北馬利安納群島',
    value: 'MP',
  },
  {
    label: '義大利',
    value: 'IT',
  },
  {
    label: '冰島',
    value: 'IS',
  },
  {
    label: '芬蘭',
    value: 'FI',
  },
  {
    label: '挪威',
    value: 'DK',
  },
  {
    label: '捷克',
    value: 'CZ',
  },
  {
    label: '奧地利',
    value: 'AT',
  },
  {
    label: '阿根廷',
    value: 'AR',
  },

]

export const CITY_MAP = [
  {
    label: '台北',
    value: '00001',
  },
  {
    label: '新北',
    value: '00002',
  },
  {
    label: '基隆',
    value: '00003',
  },
  {
    label: '桃園',
    value: '00004',
  },
  {
    label: '新竹',
    value: '00005',
  },
  {
    label: '苗栗',
    value: '00006',
  },
  {
    label: '台中',
    value: '00007',
  },
  {
    label: '南投',
    value: '00008',
  },
  {
    label: '彰化',
    value: '00009',
  },
  {
    label: '雲林',
    value: '00010',
  },
  {
    label: '嘉義',
    value: '00011',
  },
  {
    label: '台南',
    value: '00012',
  },
  {
    label: '高雄',
    value: '00013',
  },
  {
    label: '屏東',
    value: '00014',
  },
  {
    label: '宜蘭',
    value: '00015',
  },
  {
    label: '花蓮',
    value: '00016',
  },
  {
    label: '台東',
    value: '00017',
  },
  {
    label: '澎湖',
    value: '00018',
  },
  {
    label: '金門',
    value: '00019',
  },
  {
    label: '馬祖',
    value: '00020',
  },
  {
    label: '全部地區',
    value: '00099',
  },
  {
    label: '東京',
    value: '00101',
  },
  {
    label: '千葉',
    value: '00102',
  },
  {
    label: '北海道',
    value: '00103',
  },
  {
    label: '福岡',
    value: '00104',
  },
  {
    label: '岐阜',
    value: '00105',
  },
  {
    label: '兵庫',
    value: '00106',
  },
  {
    label: '沖繩',
    value: '00107',
  },
  {
    label: '大阪',
    value: '00108',
  },
  {
    label: '香川',
    value: '00109',
  },
  {
    label: '鹿兒島',
    value: '00110',
  },
  {
    label: '神奈川',
    value: '00111',
  },
  {
    label: '靜岡',
    value: '00112',
  },
  {
    label: '高知',
    value: '00113',
  },
  {
    label: '石川',
    value: '00114',
  },
  {
    label: '愛知',
    value: '00115',
  },
  {
    label: '岡山',
    value: '00116',
  },
  {
    label: '京都',
    value: '00117',
  },
  {
    label: '長野',
    value: '00118',
  },
  {
    label: '名古屋',
    value: '00119',
  },
  {
    label: '山梨',
    value: '00120',
  },
  {
    label: '長崎',
    value: '00121',
  },
  {
    label: '大分',
    value: '00122',
  },
  {
    label: '福井',
    value: '00123',
  },
  {
    label: '廣島',
    value: '00124',
  },
  {
    label: '青森',
    value: '00125',
  },
  {
    label: '埼玉',
    value: '00126',
  },
  {
    label: '熊本',
    value: '00127',
  },
  {
    label: '山口',
    value: '00128',
  },
  {
    label: '和歌山',
    value: '00129',
  },
  {
    label: '茨城',
    value: '00130',
  },
  {
    label: '富山',
    value: '00131',
  },
  {
    label: '島根',
    value: '00132',
  },
  {
    label: '愛媛',
    value: '00133',
  },
  {
    label: '滋賀',
    value: '00134',
  },
  {
    label: '奈良',
    value: '00136',
  },
  {
    label: '栃木',
    value: '00137',
  },
  {
    label: '岡山',
    value: '00138',
  },
  {
    label: '秋田',
    value: '00139',
  },
  {
    label: '鳥取',
    value: '00140',
  },
  {
    label: '山形',
    value: '00141',
  },
  {
    label: '佐賀',
    value: '00142',
  },
  {
    label: '群馬',
    value: '00143',
  },
  {
    label: '岩手',
    value: '00144',
  },
  {
    label: '三重',
    value: '00145',
  },
  {
    label: '宮城',
    value: '00146',
  },
  {
    label: '德島',
    value: '00147',
  },
  {
    label: '新潟',
    value: '00148',
  },
  {
    label: '釜山',
    value: '00201',
  },
  {
    label: '濟州',
    value: '00202',
  },
  {
    label: '江原',
    value: '00203',
  },
  {
    label: '大邱',
    value: '00204',
  },
  {
    label: '丹陽',
    value: '00205',
  },
  {
    label: '京畿',
    value: '00206',
  },
  {
    label: '仁川',
    value: '00207',
  },
  {
    label: '首爾',
    value: '00208',
  },
  {
    label: '麗水',
    value: '00209',
  },
  {
    label: '堤川',
    value: '00210',
  },
  {
    label: '全州',
    value: '00211',
  },
  {
    label: '浦項',
    value: '00212',
  },
  {
    label: '平昌',
    value: '00213',
  },
  {
    label: '洪川',
    value: '00214',
  },
  {
    label: '楊平',
    value: '00215',
  },
  {
    label: '榮州',
    value: '00216',
  },
  {
    label: '世宗',
    value: '00217',
  },
  {
    label: '蘇美',
    value: '00301',
  },
  {
    label: '佛統',
    value: '00302',
  },
  {
    label: '帕岸島',
    value: '00303',
  },
  {
    label: '曼谷',
    value: '00304',
  },
  {
    label: '清邁',
    value: '00305',
  },
  {
    label: '清萊',
    value: '00306',
  },
  {
    label: '芭達雅',
    value: '00307',
  },
  {
    label: '華欣',
    value: '00308',
  },
  {
    label: '甲米',
    value: '00309',
  },
  {
    label: '普吉',
    value: '00310',
  },
  {
    label: '宣蓬',
    value: '00311',
  },
  {
    label: '查安',
    value: '00312',
  },
  {
    label: '攀牙',
    value: '00313',
  },
  {
    label: '丹嫩莎朵',
    value: '00314',
  },
  {
    label: '合艾',
    value: '00315',
  },
  {
    label: '象島',
    value: '00316',
  },
  {
    label: '達魯島',
    value: '00317',
  },
  {
    label: '北碧',
    value: '00318',
  },
  {
    label: '考艾',
    value: '00319',
  },
  {
    label: '威色猜參',
    value: '00320',
  },
  {
    label: '那空那育',
    value: '00321',
  },
  {
    label: '拜',
    value: '00322',
  },
  {
    label: '大城',
    value: '00323',
  },
  {
    label: '羅勇',
    value: '00324',
  },
  {
    label: '沙敦',
    value: '00325',
  },
  {
    label: '安帕瓦',
    value: '00326',
  },
  {
    label: '董里',
    value: '00327',
  },
  {
    label: '美工',
    value: '00328',
  },
  {
    label: '沽島',
    value: '00329',
  },
  {
    label: '香港',
    value: '00401',
  },
  {
    label: '澳門',
    value: '00402',
  },
  {
    label: '杜拜',
    value: '00501',
  },
  {
    label: '雪梨',
    value: '00601',
  },
  {
    label: '比林加',
    value: '00602',
  },
  {
    label: '布里斯班',
    value: '00603',
  },
  {
    label: '拜倫灣',
    value: '00604',
  },
  {
    label: '昆士蘭',
    value: '00605',
  },
  {
    label: '墨爾本',
    value: '00606',
  },
  {
    label: '北臥龍崗',
    value: '00607',
  },
  {
    label: '普林斯敦',
    value: '00608',
  },
  {
    label: '瓜魯柳斯',
    value: '00701',
  },
  {
    label: '賈斯珀',
    value: '00801',
  },
  {
    label: '班夫',
    value: '00802',
  },
  {
    label: '韋吉斯',
    value: '00901',
  },
  {
    label: '日內瓦',
    value: '00902',
  },
  {
    label: '琉森',
    value: '00903',
  },
  {
    label: '蘇黎世',
    value: '00904',
  },
  {
    label: '柏林',
    value: '01001',
  },
  {
    label: '黑森',
    value: '01002',
  },
  {
    label: '巴伐利亞',
    value: '01003',
  },
  {
    label: '慕尼黑',
    value: '01004',
  },
  {
    label: '法蘭克福',
    value: '01005',
  },
  {
    label: '巴塞隆納',
    value: '01101',
  },
  {
    label: '馬德里',
    value: '01102',
  },
  {
    label: '亞維農',
    value: '01201',
  },
  {
    label: '帕賴維埃耶波斯特',
    value: '01202',
  },
  {
    label: '尼斯',
    value: '01203',
  },
  {
    label: '阿伊香檳',
    value: '01204',
  },
  {
    label: '巴黎',
    value: '01205',
  },
  {
    label: '布盧瓦',
    value: '01206',
  },
  {
    label: '波爾多',
    value: '01207',
  },
  {
    label: '坎城',
    value: '01208',
  },
  {
    label: '卡爾卡松',
    value: '01209',
  },
  {
    label: '謝爾西',
    value: '01210',
  },
  {
    label: '拉羅謝爾',
    value: '01211',
  },
  {
    label: '聖米歇爾山',
    value: '01212',
  },
  {
    label: '里昂',
    value: '01213',
  },
  {
    label: '奧利',
    value: '01214',
  },
  {
    label: '漢斯',
    value: '01215',
  },
  {
    label: '法蘭西地區魯瓦西',
    value: '01216',
  },
  {
    label: '聖但尼',
    value: '01217',
  },
  {
    label: '圖盧茲',
    value: '01218',
  },
  {
    label: '布達佩斯',
    value: '01301',
  },
  {
    label: '峇里島',
    value: '01401',
  },
  {
    label: '馬格朗',
    value: '01402',
  },
  {
    label: '萬隆',
    value: '01403',
  },
  {
    label: '本那瓦',
    value: '01501',
  },
  {
    label: '黑風洞',
    value: '01502',
  },
  {
    label: '雲頂高原',
    value: '01503',
  },
  {
    label: '檳城',
    value: '01504',
  },
  {
    label: '吉打',
    value: '01505',
  },
  {
    label: '亞庇',
    value: '01506',
  },
  {
    label: '哥打丁宜',
    value: '01507',
  },
  {
    label: '吉隆坡',
    value: '01508',
  },
  {
    label: '古晉',
    value: '01509',
  },
  {
    label: '昆達山',
    value: '01510',
  },
  {
    label: '蘭卡威',
    value: '01511',
  },
  {
    label: '馬六甲',
    value: '01512',
  },
  {
    label: '檳城',
    value: '01513',
  },
  {
    label: '八打靈再也',
    value: '01514',
  },
  {
    label: '蘭瑙',
    value: '01515',
  },
  {
    label: '沙巴',
    value: '01516',
  },
  {
    label: '山打根',
    value: '01517',
  },
  {
    label: '雪蘭莪',
    value: '01518',
  },
  {
    label: '雪邦',
    value: '01519',
  },
  {
    label: '新生村副縣',
    value: '01520',
  },
  {
    label: '新邦因帕特',
    value: '01521',
  },
  {
    label: '福克斯冰河',
    value: '01601',
  },
  {
    label: '基督城',
    value: '01602',
  },
  {
    label: '瓦納卡',
    value: '01603',
  },
  {
    label: '蒂普亞',
    value: '01604',
  },
  {
    label: '布倫亨',
    value: '01605',
  },
  {
    label: '皇后鎮',
    value: '01606',
  },
  {
    label: '羅托路亞',
    value: '01607',
  },
  {
    label: '保和島',
    value: '01701',
  },
  {
    label: '長灘島',
    value: '01702',
  },
  {
    label: '宿霧',
    value: '01703',
  },
  {
    label: '科隆',
    value: '01704',
  },
  {
    label: '艾爾尼多',
    value: '01705',
  },
  {
    label: '拉普拉普',
    value: '01706',
  },
  {
    label: '馬來',
    value: '01707',
  },
  {
    label: '曼達維',
    value: '01708',
  },
  {
    label: '馬尼拉',
    value: '01709',
  },
  {
    label: '民馬羅巴',
    value: '01710',
  },
  {
    label: '歐斯陸',
    value: '01711',
  },
  {
    label: '普林塞薩港',
    value: '01712',
  },
  {
    label: '蘇比克',
    value: '01713',
  },
  {
    label: '蒂比奧',
    value: '01714',
  },
  {
    label: '柯羅',
    value: '01801',
  },
  {
    label: '新加坡',
    value: '01901',
  },
  {
    label: '伊斯坦堡',
    value: '02001',
  },
  {
    label: '倫敦',
    value: '02101',
  },
  {
    label: '蒙哥馬利',
    value: '02201',
  },
  {
    label: '優勝美地',
    value: '02202',
  },
  {
    label: '加利福尼亞',
    value: '02203',
  },
  {
    label: '亞特蘭大',
    value: '02204',
  },
  {
    label: '海防',
    value: '02301',
  },
  {
    label: '峴港',
    value: '02302',
  },
  {
    label: '大叻',
    value: '02303',
  },
  {
    label: '油汀',
    value: '02304',
  },
  {
    label: '河內',
    value: '02305',
  },
  {
    label: '胡志明市',
    value: '02306',
  },
  {
    label: '芒楊',
    value: '02307',
  },
  {
    label: '芽莊',
    value: '02308',
  },
  {
    label: '寧平',
    value: '02309',
  },
  {
    label: '潘切',
    value: '02310',
  },
  {
    label: '廣南',
    value: '02311',
  },
  {
    label: '會安',
    value: '02312',
  },
  {
    label: '西寧',
    value: '02313',
  },
  {
    label: '下龍',
    value: '02314',
  },
  {
    label: '富國',
    value: '02315',
  },
  {
    label: '辛特拉',
    value: '02401',
  },
  {
    label: '波多',
    value: '02402',
  },
  {
    label: '奧斯陸',
    value: '02501',
  },
  {
    label: '阿姆斯特丹',
    value: '02601',
  },
  {
    label: '塞班島',
    value: '02701',
  },
  {
    label: '羅馬',
    value: '02801',
  },
  {
    label: '雷克雅維克',
    value: '02901',
  },
  {
    label: '科帕沃於爾',
    value: '02902',
  },
  {
    label: '羅瓦涅米',
    value: '03001',
  },
  {
    label: '赫爾辛基',
    value: '03002',
  },
  {
    label: '哥本哈根',
    value: '03101',
  },
  {
    label: '布拉格',
    value: '03201',
  },
  {
    label: '戈爾丘夫耶尼科夫',
    value: '03202',
  },
  {
    label: '下奧地利',
    value: '03301',
  },
  {
    label: '瓦滕斯',
    value: '03302',
  },
  {
    label: '維也納',
    value: '03303',
  },
  {
    label: '埃塞薩',
    value: '03401',
  },

]

export const COUNTRY_DATA = {
  'TW': '台灣',
  'JP': '日本',
  'KR': '韓國',
  'TH': '泰國',
  'CN': '港澳',
  'AE': '阿聯',
  'AU': '澳洲',
  'BR': '巴西',
  'CA': '加拿大',
  'CH': '瑞士',
  'DE': '德國',
  'ES': '西班牙',
  'FR': '法國',
  'HU': '匈牙利',
  'ID': '印尼',
  'MY': '馬來西亞',
  'NZ': '紐西蘭',
  'PH': '菲律賓',
  'PW': '帛琉',
  'SG': '新加坡',
  'TR': '土耳其',
  'UK': '英國',
  'US': '美國',
  'VN': '越南',
  'PT': '葡萄牙',
  'NO': '挪威',
  'NL': '荷蘭',
  'MP': '北馬利安納群島',
  'IT': '義大利',
  'IS': '冰島',
  'FI': '芬蘭',
  'DK': '挪威',
  'CZ': '捷克',
  'AT': '奧地利',
  'AR': '阿根廷',
}

export const CITY_DATA = {
  '00001': '台北',
  '00002': '新北',
  '00003': '基隆',
  '00004': '桃園',
  '00005': '新竹',
  '00006': '苗栗',
  '00007': '台中',
  '00008': '南投',
  '00009': '彰化',
  '00010': '雲林',
  '00011': '嘉義',
  '00012': '台南',
  '00013': '高雄',
  '00014': '屏東',
  '00015': '宜蘭',
  '00016': '花蓮',
  '00017': '台東',
  '00018': '澎湖',
  '00019': '金門',
  '00020': '馬祖',
  '00099': '全部地區',
  '00101': '東京',
  '00102': '千葉',
  '00103': '北海道',
  '00104': '福岡',
  '00105': '岐阜',
  '00106': '兵庫',
  '00107': '沖繩',
  '00108': '大阪',
  '00109': '香川',
  '00110': '鹿兒島',
  '00111': '神奈川',
  '00112': '靜岡',
  '00113': '高知',
  '00114': '石川',
  '00115': '愛知',
  '00116': '岡山',
  '00117': '京都',
  '00118': '長野',
  '00119': '名古屋',
  '00120': '山梨',
  '00121': '長崎',
  '00122': '大分',
  '00123': '福井',
  '00124': '廣島',
  '00125': '青森',
  '00126': '埼玉',
  '00127': '熊本',
  '00128': '山口',
  '00129': '和歌山',
  '00130': '茨城',
  '00131': '富山',
  '00132': '島根',
  '00133': '愛媛',
  '00134': '滋賀',
  '00136': '奈良',
  '00137': '栃木',
  '00138': '岡山',
  '00139': '秋田',
  '00140': '鳥取',
  '00141': '山形',
  '00142': '佐賀',
  '00143': '群馬',
  '00144': '岩手',
  '00145': '三重',
  '00146': '宮城',
  '00147': '德島',
  '00148': '新潟',
  '00201': '釜山',
  '00202': '濟州',
  '00203': '江原',
  '00204': '大邱',
  '00205': '丹陽',
  '00206': '京畿',
  '00207': '仁川',
  '00208': '首爾',
  '00209': '麗水',
  '00210': '堤川',
  '00211': '全州',
  '00212': '浦項',
  '00213': '平昌',
  '00214': '洪川',
  '00215': '楊平',
  '00216': '榮州',
  '00217': '世宗',
  '00301': '蘇美',
  '00302': '佛統',
  '00303': '帕岸島',
  '00304': '曼谷',
  '00305': '清邁',
  '00306': '清萊',
  '00307': '芭達雅',
  '00308': '華欣',
  '00309': '甲米',
  '00310': '普吉',
  '00311': '宣蓬',
  '00312': '查安',
  '00313': '攀牙',
  '00314': '丹嫩莎朵',
  '00315': '合艾',
  '00316': '象島',
  '00317': '達魯島',
  '00318': '北碧',
  '00319': '考艾',
  '00320': '威色猜參',
  '00321': '那空那育',
  '00322': '拜',
  '00323': '大城',
  '00324': '羅勇',
  '00325': '沙敦',
  '00326': '安帕瓦',
  '00327': '董里',
  '00328': '美工',
  '00329': '沽島',
  '00401': '香港',
  '00402': '澳門',
  '00501': '杜拜',
  '00601': '雪梨',
  '00602': '比林加',
  '00603': '布里斯班',
  '00604': '拜倫灣',
  '00605': '昆士蘭',
  '00606': '墨爾本',
  '00607': '北臥龍崗',
  '00608': '普林斯敦',
  '00701': '瓜魯柳斯',
  '00801': '賈斯珀',
  '00802': '班夫',
  '00901': '韋吉斯',
  '00902': '日內瓦',
  '00903': '琉森',
  '00904': '蘇黎世',
  '01001': '柏林',
  '01002': '黑森',
  '01003': '巴伐利亞',
  '01004': '慕尼黑',
  '01005': '法蘭克福',
  '01101': '巴塞隆納',
  '01102': '馬德里',
  '01201': '亞維農',
  '01202': '帕賴維埃耶波斯特',
  '01203': '尼斯',
  '01204': '阿伊香檳',
  '01205': '巴黎',
  '01206': '布盧瓦',
  '01207': '波爾多',
  '01208': '坎城',
  '01209': '卡爾卡松',
  '01210': '謝爾西',
  '01211': '拉羅謝爾',
  '01212': '聖米歇爾山',
  '01213': '里昂',
  '01214': '奧利',
  '01215': '漢斯',
  '01216': '法蘭西地區魯瓦西',
  '01217': '聖但尼',
  '01218': '圖盧茲',
  '01301': '布達佩斯',
  '01401': '峇里島',
  '01402': '馬格朗',
  '01403': '萬隆',
  '01501': '本那瓦',
  '01502': '黑風洞',
  '01503': '雲頂高原',
  '01504': '檳城',
  '01505': '吉打',
  '01506': '亞庇',
  '01507': '哥打丁宜',
  '01508': '吉隆坡',
  '01509': '古晉',
  '01510': '昆達山',
  '01511': '蘭卡威',
  '01512': '馬六甲',
  '01513': '檳城',
  '01514': '八打靈再也',
  '01515': '蘭瑙',
  '01516': '沙巴',
  '01517': '山打根',
  '01518': '雪蘭莪',
  '01519': '雪邦',
  '01520': '新生村副縣',
  '01521': '新邦因帕特',
  '01601': '福克斯冰河',
  '01602': '基督城',
  '01603': '瓦納卡',
  '01604': '蒂普亞',
  '01605': '布倫亨',
  '01606': '皇后鎮',
  '01607': '羅托路亞',
  '01701': '保和島',
  '01702': '長灘島',
  '01703': '宿霧',
  '01704': '科隆',
  '01705': '艾爾尼多',
  '01706': '拉普拉普',
  '01707': '馬來',
  '01708': '曼達維',
  '01709': '馬尼拉',
  '01710': '民馬羅巴',
  '01711': '歐斯陸',
  '01712': '普林塞薩港',
  '01713': '蘇比克',
  '01714': '蒂比奧',
  '01801': '柯羅',
  '01901': '新加坡',
  '02001': '伊斯坦堡',
  '02101': '倫敦',
  '02201': '蒙哥馬利',
  '02202': '優勝美地',
  '02203': '加利福尼亞',
  '02204': '亞特蘭大',
  '02301': '海防',
  '02302': '峴港',
  '02303': '大叻',
  '02304': '油汀',
  '02305': '河內',
  '02306': '胡志明市',
  '02307': '芒楊',
  '02308': '芽莊',
  '02309': '寧平',
  '02310': '潘切',
  '02311': '廣南',
  '02312': '會安',
  '02313': '西寧',
  '02314': '下龍',
  '02315': '富國',
  '02401': '辛特拉',
  '02402': '波多',
  '02501': '奧斯陸',
  '02601': '阿姆斯特丹',
  '02701': '塞班島',
  '02801': '羅馬',
  '02901': '雷克雅維克',
  '02902': '科帕沃於爾',
  '03001': '羅瓦涅米',
  '03002': '赫爾辛基',
  '03101': '哥本哈根',
  '03201': '布拉格',
  '03202': '戈爾丘夫耶尼科夫',
  '03301': '下奧地利',
  '03302': '瓦滕斯',
  '03303': '維也納',
  '03401': '埃塞薩',
}