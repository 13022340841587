/*
 * 日期時間相關驗證判斷 /格式轉換
 *  ├ 待補充
 *  └ 待補充
 */

import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { DateObject } from 'react-multi-date-picker'
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  TIME_FORMAT, 
} from 'config/date'

export const handleDateFormat = (dateTime, format = DATE_FORMAT) => {
  if (Boolean(dateTime) === false) return ''
  const formatDateTime = dayjs(dateTime).format(format)
  return formatDateTime === 'Invalid Date' ? null : formatDateTime
}

export const isAfterDate = (checkDate, sortDate) => {
  return dayjs(checkDate).isAfter(sortDate)
}

export const isBeforeDate = (checkDate, sortDate) => {
  return dayjs(checkDate).isBefore(sortDate)
}

dayjs.extend(isSameOrAfter)
export const isSameOrAfterDate = (checkDate, compareDate) => {
  return dayjs(checkDate).isSameOrAfter(compareDate)
}

export const addDays = (date, days) => {
  return dayjs(date).add(days, 'days')
}

// limit original format by 'YYYYMMDD HHmmSS'
export const convertToHotaiDateFormat = (date, time) => {
  let formatDate = ''
  let formatTime = ''

  if (Boolean(date)) {
    const year = date.slice(0, 4)
    const month = date.slice(4, 6)
    const day = date.slice(6)

    formatDate = `${year}-${month}-${day}`
  }

  if (Boolean(time)) {
    const hour = time.slice(0, 2)
    const minutes = time.slice(2, 4)
    const second = time.slice(4)
    formatTime = `${Boolean(date) ? ' ' : ''}${hour}:${minutes}:${second}`
  }

  return formatDate + formatTime
}

// limit original format by 'YYYY-MM-DD'
export const convertToEcDateFormat = (dateTime) => {
  if (Boolean(dateTime) === false) return ''

  return dateTime.split('-').join('')
}

// return format 'YYYY-MM-DD'
export const dateFormat = (dateTime) => {
  if (Boolean(dateTime) === false) return ''
  return dayjs(dateTime).format(DATE_FORMAT)
}

// return format 'HH:mm:ss'
export const timeFormat = (dateTime) => {
  if (Boolean(dateTime) === false) return ''
  return dayjs(dateTime).format(TIME_FORMAT)
}

// return format 'YYYY-MM-DD HH:mm:ss'
export const dateTimeFormat = (dateTime) => {
  if (Boolean(dateTime) === false) return ''
  return dayjs(dateTime).format(DATETIME_FORMAT)
}

// handle react-multi-date-picker value
// limitRange : type number, only for prev range
export const convertCalendarRange = (range = [], limitRange) => {
  const dateFrom = range[0]
  const dateTo = range[1]
  let dateFromString = ''
  let dateToString = ''

  if (Boolean(limitRange)) {
    const limitDateFrom = dateFrom || new DateObject().subtract(limitRange, 'days')
    const limitDateTo = dateTo || new DateObject()
    dateFromString = limitDateFrom.format(DATE_FORMAT)
    dateToString = limitDateTo.format(DATE_FORMAT)

    // check if overRange
    const maxDateToString = new DateObject(dateFrom).add(limitRange, 'days').format(DATE_FORMAT)
    if (dateToString > maxDateToString) return ''
  } else {
    if (Boolean(dateFrom)) dateFromString = dateFrom.format(DATE_FORMAT)
    if (Boolean(dateTo)) dateToString = dateTo.format(DATE_FORMAT)
  }

  const timeDash = Boolean(dateToString) ? ' ~ ' : ''
  return dateFromString + timeDash + dateToString
}

export const convertCalendar = (date) => {
  const dateFrom = range[0]
  const dateTo = range[1]
  let dateFromString = ''
  let dateToString = ''

  if (Boolean(limitRange)) {
    const limitDateFrom = dateFrom || new DateObject().subtract(limitRange, 'days')
    const limitDateTo = dateTo || new DateObject()
    dateFromString = limitDateFrom.format(DATE_FORMAT)
    dateToString = limitDateTo.format(DATE_FORMAT)

    // check if overRange
    const maxDateToString = new DateObject(dateFrom).add(limitRange, 'days').format(DATE_FORMAT)
    if (dateToString > maxDateToString) return ''
  } else {
    if (Boolean(dateFrom)) dateFromString = dateFrom.format(DATE_FORMAT)
    if (Boolean(dateTo)) dateToString = dateTo.format(DATE_FORMAT)
  }

  const timeDash = Boolean(dateToString) ? ' ~ ' : ''
  return dateFromString + timeDash + dateToString
}

export const handleTimeAdjustment = (
  startTime,
  endTime,
  transitionStart = 0,
  transitionEnd = 0,
) => {
  if (!startTime || !endTime) return false
  const format = 'YYYY-MM-DD HH:mm:ss'
  const today = dayjs().format(format)
  const start = dayjs(startTime).add(0 - transitionStart, 'minutes') 
  const end = dayjs(endTime).add(transitionEnd, 'minutes') 
  return isSameOrAfterDate(today, start) && isBeforeDate(today, end)
}

// cookie 過期時間，預設1年過期
export const cookieExpires = (day = 365) => {
  return dayjs().add(day, 'day').toDate().toUTCString()
}

export const handleCheckBannerTime = ( startTime, endTime) => {
  const start = startTime === undefined || startTime === '' ? '2024-01-01 00:00:00' : startTime
  const end = endTime === undefined || endTime === '' ? '9999-01-01 00:00:00' : endTime
     
  return handleTimeAdjustment(start, end)
}