import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
// import { PersistGate } from 'redux-persist/integration/react'
import { wrapper } from 'store/index'
import Head from 'next/head'
import Layout from 'common/layout/Layout'
import { WEB_TITLE, HEAD_DEFAULT_DATA } from 'config/head'
import 'styles/base/modern-normalize.css'
import { ENV_INFO } from 'config/config'
import { useRouter } from 'next/router'

const App = ({
  Component, pageProps, ...rest
}) => {
  const router = useRouter()
  const { store, props } = wrapper.useWrappedStore(rest)
  const {
    headers,
    currentUrl,
    pathQuery,
  } = pageProps

  useEffect(() => {
    if (headers !== undefined) {
      const host = headers.host
      const subdomain = host.split('.')[0] // 提取子域名
      const v = `v${ENV_INFO.version}`
      console.log('version', v)
      // console.log('pathQuery', v, pathQuery)
      // console.log('currentUrl', v, currentUrl)
      // console.log('headers', v, headers)
      // console.log('host', v, host)
      // console.log('subdomain', v, subdomain)

      // 避免更新 prod env 時，UAT GTM 測試失敗，所以改由此處切換
      // const gtm = `GTM-${['ec-web-prod', 'travel'].includes(subdomain) ? 'TP4BT2W' : 'NLLW6G3'}`

      // console.log('SSR API Root', v, ENV_INFO.apiRoot)
      // console.log('gtm', v, gtm)
      // console.log('nonce', v, process.env.nonce)

      if (ENV_INFO.gtm !== '') {
        const script = document.createElement('script')
        // GTM ID
        // script.setAttribute('nonce', process.env.nonce)
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${ENV_INFO.gtm}')`
  
        document.head.appendChild(script)
      }
    }
  }, [headers])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content={ENV_INFO.gtm === 'GTM-TP4BT2W' ? 'index,follow' : 'noindex,nofollow'} />
        <meta name="googlebot" content={ENV_INFO.gtm === 'GTM-TP4BT2W' ? 'index,follow' : 'noindex,nofollow'} />
        <meta property="og:type" content={HEAD_DEFAULT_DATA.TYPE} key="type" />
        <meta property="og:url" content={HEAD_DEFAULT_DATA.URL} key="url" />
        <meta property="og:image" content={HEAD_DEFAULT_DATA.IMAGE} key="image" /> 
        <meta property="og:title" content={HEAD_DEFAULT_DATA.TITLE} key="title" />
        <meta property="og:description" content={HEAD_DEFAULT_DATA.DESC} key="description" />
        <meta property="og:site_name" content={WEB_TITLE} key="site_name" />
        <link rel="icon" href={HEAD_DEFAULT_DATA.ICON} />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/ico/favicon-180-precomposed.png" />
      </Head>
      <Provider store={store}>
        
        {ENV_INFO.gtm !== '' && <>
        
          {/* Google Tag Manager (noscript) */}
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
            <iframe src=https://www.googletagmanager.com/ns.html?id=${ENV_INFO.gtm}
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `,
            }}
          />
          {/* End Google Tag Manager (noscript) */}

        </>}


        {/* <PersistGate persistor={store.persistor} loading={null}> */}

        <Layout>
          <Component {...pageProps} />
        </Layout>
        
        {/* </PersistGate> */}
      </Provider>
    </>
  )
}

export default App
