import PropTypes from 'prop-types'
import {
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react'
import classReader from 'utils/classReader'

import Button from 'common/Button'

const Input = (props) => {  
  const {
    name,
    className,
    styleName,
    size,
    id,
    value,
    type,
    placeholder,
    hint,
    validate,
    maxLength,
    dense,
    rounded,
    color,
    innerColor,
    counter,
    clearable,
    disabled,
    readonly,
    hideSpin,
    prependChild,
    appendChild,
    register,
    onChange,
    onKeyDown,
    onFocus,
    onBlur,
    onValidate,
    errorMessage,
  } = props

  const [isFocus, setIsFocus] = useState(false)
  const [invalidMsg, setInvalidMsg] = useState('')
  const [registerOptions, setRegisterOptions] = useState({})

  const handleValid = (value) => {
    let invalidMsg = ''
    for (const property in validate) {
      const validFunc = validate[property]
      const validResult = validFunc(value)

      if (typeof validResult === 'string') {
        invalidMsg = validResult
        break
      }
    }

    setInvalidMsg(invalidMsg)
    onValidate(invalidMsg === '')
  }

  const handleChange = useCallback((e) => {
    const newValue = e.target.value
    handleValid(newValue)
    onChange(newValue, name)
  }, [])

  const handleKeyDown = (e) => {
    const newValue = e.target.value
    const key = e.key

    onKeyDown(
      key, newValue, name,
    )
  }

  const handleBlur = useCallback((e) => {
    if (readonly) return

    const newValue = e?.target?.value
    handleValid(newValue)
    onBlur(newValue, name)
    setIsFocus(false)
  }, [])

  const handleFocus = () => {
    if (readonly) return
    setIsFocus(true)
    onFocus()
  }

  const atClickReset = () => {
    handleValid('')
    onChange('', name)
  }

  useEffect(() => {
    if (typeof register !== 'function') return

    setRegisterOptions(register(name, {
      validate,
      onChange: handleChange,
      onBlur: handleBlur,
    }))
  }, [
    name,
    register,
    validate,
    handleChange,
    handleBlur,
  ])

  const isShowClearButton = clearable && readonly === false && disabled === false && Boolean(value)
  return (
    <div
      className={
        classReader(
          'input field--outlined no-wrap',
          { [`CommonStyled ${styleName}`]: 'field--with-bottom' }, // can overwritten this className style
          { 'field--highlighted': Boolean(invalidMsg || errorMessage) || isFocus },
          { 'field--dense': dense },
          { 'field--rounded': rounded },
          { 'field--disabled': disabled },
          { 'field--readonly': readonly },
          { 'input--hide-spin-button': hideSpin },
          { 'pure': className },
        )}
      id={id}
      data-error={Boolean(errorMessage)}
    >
      <div className={classReader('field__inner relative-position col p-0')}>
        <div
          className={classReader(
            'relative-position d-flex no-wrap',
            { [`CommonStyled ${styleName}`]: 'field__control' }, // can overwritten this className style
            `field-size-${size}`,
            { 'text-red': Boolean(invalidMsg || errorMessage) },
            { [`text-${color}`]: Boolean(color) && invalidMsg === '' && errorMessage === '' },
            { [`bg-${innerColor}`]: Boolean(innerColor) },
          )}
          tabIndex="-1"
        >
          {Boolean(prependChild) && (
            <div className={classReader(`field-size-${size}`, 'field__prepend d-flex no-wrap align-items-center text-gray-9')}>
              {prependChild}
            </div>
          )}

          <div className={classReader('field__control-container col relative-position d-flex no-wrap p-0')}>
            <input
              className={classReader('field__native q-placeholder')}
              tabIndex="0"
              name={name}
              value={value}
              type={type}
              placeholder={placeholder || ''}
              maxLength={maxLength}
              disabled={disabled}
              readOnly={readonly}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              onFocus={handleFocus}
              autoComplete="off"
              {...registerOptions}
            />
          </div>

          {isShowClearButton && (
            <div className={classReader(`field-size-${size}`, 'field__append d-flex no-wrap align-items-center')}>
              <Button
                className={classReader('field__clearable-action')}
                icon="stroke-close"
                color="gray-3"
                iconColor="white"
                size="sm"
                onClick={atClickReset}
                unelevated
                round
              />
            </div>
          )}

          {Boolean(appendChild) && (
            <div className={classReader(`field-size-${size}`, 'field__append d-flex no-wrap align-items-center text-gray-7')}>
              {appendChild}
            </div>
          )}
        </div>

        {(Boolean(invalidMsg || errorMessage || hint) || counter) && (
          <div className={classReader('field__bottom field__bottom--animated d-flex align-items-flex-start',
            { 'field__bottom--invalid': Boolean(invalidMsg || errorMessage) })}
          >
            <div className={classReader('field__messages col p-0')}>{invalidMsg || errorMessage || hint}</div>
            {counter && (
              <div className={classReader('input__counter')}>
                {value?.length || 0} {Boolean(maxLength) && ` / ${maxLength}`}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  styleName: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf([
    // 'color',
    // 'date',
    // 'datetime-local',
    'email',
    // 'file',
    // 'hidden',
    // 'image',
    // 'month',
    'number',
    'password',
    // 'range',
    // 'reset',
    // 'search',
    // 'submit',
    'tel',
    'text',
    // 'time',
    // 'url',
    // 'week'
  ]),
  placeholder: PropTypes.string,
  hint: PropTypes.string,
  errorMessage: PropTypes.string, // without react-hook form and form tag and any action
  validate: PropTypes.object,
  maxLength: PropTypes.number,
  color: PropTypes.string,
  innerColor: PropTypes.string,
  dense: PropTypes.bool,
  rounded: PropTypes.bool,
  counter: PropTypes.bool,
  clearable: PropTypes.bool,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  hideSpin: PropTypes.bool,
  prependChild: PropTypes.node,
  appendChild: PropTypes.node,
  register: PropTypes.func, // with react-hook form
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onValidate: PropTypes.func,
}

Input.defaultProps = {
  styleName: '',
  size: 'md',
  type: 'text',
  color: 'primary',
  innerColor: 'white',
  dense: false,
  rounded: false,
  counter: false,
  clearable: false,
  readonly: false,
  disabled: false,
  hideSpin: false,
  onChange: () => { },
  onKeyDown: () => { },
  onFocus: () => { },
  onBlur: () => { },
  onValidate: () => { },
}

export default memo(Input)
