import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import point from 'apis/hotai/point'
import { setErrorState } from 'slices/errorSlice'

const pointInitial = { 
  hotaiPoint: 0,
  hotaiPointIsReady: false,
}

export const searchTotalPoints = createAsyncThunk('point/searchTotalPoints',
  async (params, thunkAPI) => {
    try {
      const result = await point.searchPoints({ queryType: 1 })
      const { data } = result
      return data && data.point || 0
    } catch (error) {
      thunkAPI.dispatch(setErrorState(error))
    }
  })

export const pointSlice = createSlice({
  name: 'point',
  initialState: pointInitial,
  reducers: {
    setHotaiPointState(state, action) {
      state.hotaiPoint = action.payload
    },
    setHotaiPointIsReady(state, action) {
      state.hotaiPointIsReady = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('point/searchTotalPoints/fulfilled', (state, action) => {
        state.hotaiPoint = action.payload
        state.hotaiPointIsReady = true
      })
      .addMatcher((action) => {
        return action.type.endsWith('/fulfilled') &&
            (
              action.type.startsWith('localAuth/ecLocalSignIn') ||
              action.type.startsWith('auth/ecSignIn')
            )
      },
      (state, action) => {
        state.hotaiPoint = action.payload.hotaiPoint
      })
      .addMatcher((action) => (
        action.type === 'auth/resetAuthSetting' ||
          action.type === 'auth/ecSignOut/fulfilled' ||
          action.type === 'auth/ecSignOut/rejected'
      ),
      () => pointInitial)
  },
})

const { actions, reducer } = pointSlice

export const { 
  setHotaiPointState, 
  setHotaiPointIsReady,
} = actions

export const selectHotaiPoint = (state) => state.point.hotaiPoint
export const selectHotaiPointIsReady = (state) => state.point.hotaiPointIsReady
export default reducer