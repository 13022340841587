import { FOOTER_COMPANY_INFO, FOOTER_NAV } from 'config/footer'
import Link from 'next/link'
import { memo } from 'react'
import classReader from 'utils/classReader'
import $isEmpty from 'lodash/isEmpty'

const Footer = () => {
  const data = new Date()

  return (
    <footer className={classReader('footer mt-5')}>
      <div>
        <div className={classReader('container pt-3 pb-3')}>
          <div className={classReader('row')}>
            <div className={classReader('col-12 col-md-7 p-0 d-none d-md-block')}>
              <div className={classReader('footer__menu')}>
                <div className={classReader('row')}>
                  {
                    FOOTER_NAV.map((item, index) => {
                      return (
                        <div key={index} className={classReader('p-0 mb-1', item.className)}>
                          <h2><b>{item.title}</b></h2>
                          <ul>
                            {
                              item.option.map((optionItem, optionIndex) => {
                                return (
                                  <li key={optionIndex}>
                                    <Link
                                      className={classReader({ pure: 'gtm-footer-menu' })}
                                      rel={optionItem.target === '_blank' ? 'noreferrer noopenner' : ''}
                                      prefetch={false}
                                      href={optionItem.link}
                                      target={optionItem.target}
                                      aria-label={optionItem.name}
                                    >
                                      {optionItem.name}
                                    </Link>
                                  </li>
                                )
                              })
                            }
                          </ul>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className={classReader('col-12 col-md-5 p-0')}>
              <div className={classReader('footer__company')}>
                <div className={classReader('d-none d-md-block')}>
                  <h2>{FOOTER_COMPANY_INFO.title}</h2>
                </div>
                <ul>
                  {
                    FOOTER_COMPANY_INFO.option.map((companyItem, companyIndex) => {
                      return <li key={companyIndex} className={classReader('text-gray-5', { 'mb-4': companyIndex === 4 })}>
                        {companyItem !== '' && <>
                          {companyItem}
                        </>}

                        {companyIndex === 3 && <>請點擊<Link href="/custom-service" className={classReader('mx-1 text-primary')} style={{ textDecoration: 'underline' }}>客服專區</Link>專人為您服務</>}
                        {companyIndex === 4 && <>
                          {
                            [
                              [`旅遊專線：${FOOTER_COMPANY_INFO.customer.tel.join(' / ')}`, `${FOOTER_COMPANY_INFO.customer.serviceHours}`].filter(Boolean).join('  '),
                              FOOTER_COMPANY_INFO.fax.length === 0 ? '' : `傳真：${FOOTER_COMPANY_INFO.fax.join(' / ')}`,
                              FOOTER_COMPANY_INFO.email.length === 0 ? '' : `旅遊聯絡信箱：${FOOTER_COMPANY_INFO.email}`,
                              FOOTER_COMPANY_INFO.attention.length === 0 ? '' : FOOTER_COMPANY_INFO.attention,
                            ].filter(Boolean).join(' | ')
                          }
                        </>}

                      </li>
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classReader('footer__copyright')}>
        <div className={classReader('container')}>Copyright © {data.getFullYear()} Hotai Connected Co.,Ltd Powered by Hotai Motor Corporation</div>
      </div>
    </footer>
  )
}

export default memo(Footer)